<template>
    <div style="position: absolute; top: 1990px; left: 730px">
        <img style="width: 1720px; height: 100%; object-fit: contain" :src="getAssetsFile('imgs/roadLine.png')" alt="" />
    </div>
</template>

<script setup>
import useMapStore from '@/store/map';
import { onMounted } from 'vue';
import { getAssetsFile } from '@/utils/assets';
const mapStore = useMapStore();
// const isMounted = ref(false);
onMounted(() => {
    // mapStore.showLevel = 4;
    // isMounted.value = true;
    console.log('=====mapStore.showLevel', mapStore.showLevel);
});
</script>

<style></style>
