<template>
    <div style="width: 100%; height: 100%;background-color: #000;display: flex;justify-content: center;align-items: center;">
        <div id="landspace-wrapper" class="landspace">
            <HomePage></HomePage>
        </div>
    </div>
</template>

<script setup>
import HomePage from '@/components/homePage.vue';
import start from '@/components/global/start';
import { pv } from '@/utils/tools/track.js'
import { onMounted } from 'vue';
onMounted(() => {
    start.open(); //加载完成弹出活动介绍
    pv('idm_main'); // 百度页面事件埋点
});
</script>

<style lang="scss">
.landspace {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 680px; 
}
</style>
