/* jshint esversion: 6 */
import { emberTimeStamp } from './lib.js';

/**
 * 意大利设计地图埋点
 */

export const pv = (pageName) => {
    console.log('%c -----> 页面事件', 'font-size:13px; background:pink; color:#bf2c9f;', pageName);
    _hmt.push(['_trackEvent', pageName, 'pageView', emberTimeStamp()]);
};

export const ev = (pageName, buttonName) => {
    console.log('%c -----> 按钮事件', 'font-size:13px; background:pink; color:#bf2c9f;', `${pageName} ----- ${buttonName}`);
    _hmt.push(['_trackEvent', pageName, buttonName, emberTimeStamp()]);
};
