<template>
    <div>
        <div v-for="(item, index) in productData" :key="'product' + index" style="position: absolute" :style="{ top: item.top, left: item.left }">
            <div
                v-if="
                    mapStore.productType == 0 ||
                    (mapStore.productType == item.type && mapStore.productName == '0') ||
                    (mapStore.productType == item.type && mapStore.productName == item.typeName)
                "
                @click="selectItem(item)"
                class="itemClick"
                style="padding: 5px 20px; color: black; border-radius: 40px"
                :style="{
                    backgroundColor: currentItem && currentItem.id == item.id ? colorData[item.type - 1] : '#fff',
                    border: currentItem && currentItem.id == item.id ? '2px solid #fff' : '2px solid #9fd17c',
                }"
            >
                <p style="font-size: 20px" :style="{ color: currentItem && currentItem.id == item.id ? '#fff' : colorData[item.type - 1] }">
                    <!-- <img :src="getAssetsFile('imgs/icon_type' + item.type + '.svg')" alt="" /> <span :style="{ color: colorData[item.type - 1] }">{{ item.name }}</span> -->
                    <!-- <svg class="icon" aria-hidden="true">
                        <use :xlink:href="'#icon-icon_type'+item.type"></use>
                    </svg> -->
                    <span :class="'iconfont icon-icon_type' + item.type"></span>
                    <span style="margin-left: 5px">{{ item.name }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { productData, colorData } from '@/api/productData.js';
import productDetails from '@/components/global/productDetails';
import useMapStore from '@/store/map';
const mapStore = useMapStore();
const currentItem = ref(null);
function selectItem(val) {
    currentItem.value = val; // 当前选中项
    productDetails.open(val); // 打开详情弹窗
}
</script>

<style></style>
