<template>
    <div style="width: 100%; height: 100%; position: relative; background-color: rgb(213, 235, 205)">
        <!-- 顶部title -->
        <div
            v-if="mapStore.enter"
            class="title"
            style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 80px;
                padding: 20px 20px 0;
                z-index: 9;
                background-color: rgba(213, 235, 205, 0.5);
                pointer-events: none;
            "
            :class="{ shadow: mapStore.showLevel == 5 }"
        >
            <div style="float: left; font-size: 18px; font-family: Alimama; color: #006221">
                <p>意大利设计地图，</p>
                <p>探索最美意大利设计</p>
            </div>
            <div style="float: right">
                <img style="height: 40px; object-fit: contain" src="@/assets/imgs/logo.png" alt="" />
            </div>
        </div>
        <!-- 缩放以及返回 -->
        <div
            v-if="mapStore.enter"
            style="
                width: 100%;
                height: 32px;
                position: absolute;
                top: 80px;
                left: 0;
                background-color: rgba(213, 235, 205, 0.5);
                padding: 0 20px;
                z-index: 100;
                pointer-events: none;
            "
            :class="{ shadow: mapStore.showLevel == 5 }"
        >
            <div v-if="mapStore.currentPage != 'init'" style="float: left; position: relative; z-index: 2">
                <img @click="backLast" style="width: 32px; object-fit: contain; pointer-events: auto" src="@/assets/imgs/icon_back.png" alt="" />
            </div>
            <div v-if="!(mapStore.showLevel == 4 || mapStore.showLevel == 5)" style="float: right">
                <img @click="addMapScale" style="width: 32px; object-fit: contain; pointer-events: auto" src="@/assets/imgs/icon_add.png" alt="" />
                <img @click="reduceMapScale" style="width: 32px; object-fit: contain; pointer-events: auto; margin-left: 15px" src="@/assets/imgs/icon_reduce.png" alt="" />
            </div>
        </div>
        <!-- 下拉选择框 -->
        <div
            v-if="!(mapStore.showLevel == 4 || mapStore.showLevel == 5)"
            style="position: absolute; top: 112px; left: 0; width: 100%; padding: 8px 20px 0; background-color: rgba(213, 235, 205, 0.5); z-index: 9"
        >
            <van-dropdown-menu active-color="#006221">
                <van-icon
                    style="position: absolute; pointer-events: none; transform: translateY(-50%); z-index: 9999; top: 50%; right: 55%"
                    name="arrow-down"
                    color="#000"
                    size="35px"
                />
                <van-icon
                    style="position: absolute; pointer-events: none; transform: translateY(-50%); z-index: 9999; top: 50%; right: 4%; paddding-right: 10px; background-color: #fff"
                    name="arrow-down"
                    :color="selectType == 0 ? '#999' : '#000'"
                    size="35px"
                />
                <van-dropdown-item v-model="selectType" :options="productType"></van-dropdown-item>
                <van-dropdown-item v-if="selectType == 1 || selectType == 0" v-model="selectValue" :options="productName1" :disabled="selectType == 0"></van-dropdown-item>
                <van-dropdown-item v-if="selectType == 2" v-model="selectValue" :options="productName2" :disabled="selectType == 0"></van-dropdown-item>
                <van-dropdown-item v-if="selectType == 3" v-model="selectValue" :options="productName3" :disabled="selectType == 0"></van-dropdown-item>
                <van-dropdown-item v-if="selectType == 4" v-model="selectValue" :options="productName4" :disabled="selectType == 0"></van-dropdown-item>
            </van-dropdown-menu>
        </div>
        <!-- 地标说明面板 -->
        <Channel v-if="mapStore.showLevel != 5" style="position: absolute; top: 180px; right: 0; width: 125px; padding: 8px 20px 0; z-index: 2"></Channel>
        <!-- 地图 -->
        <div style="width: 100%; height: 100%; position: relative">
            <Map></Map>
        </div>
        <!-- 背景音乐控制按钮 -->
        <!-- <div
            v-if="mapStore.enter"
            style="
                position: absolute;
                bottom: 65px;
                left: 0;
                width: 100%;
                height: 100px;
                padding: 0 20px;
                pointer-events: auto;
                font-size: 0px;
            "
            :class="{ shadow: mapStore.showLevel == 5 }"
        >
            <div style="width:64px;text-align: center; float: right">
                <img
                    @click="bgMusicPlayOuPause"
                    style="width: 50px; object-fit: contain; pointer-events: auto; font-size: 0"
                    :src="getAssetsFile('imgs/icon_bgm_' + mapStore.bgAudioPlaying + '.png')"
                    alt=""
                />
            </div>
        </div> -->
        <!-- 底部功能键 -->
        <div
            v-if="mapStore.enter"
            style="
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100px;
                padding: 0 20px;
                pointer-events: none;
                font-size: 16px;
                font-family: HarmonyOS;
                color: #4d4d4d;
            "
            :class="{ shadow: mapStore.showLevel == 5 }"
        >
            <div v-if="mapStore.mapScale <= 1" style="text-align: center; float: left">
                <img @click="openRoadLine" style="width: 50px; object-fit: contain; pointer-events: auto; font-size: 0" src="@/assets/imgs/icon_road.png" alt="" />
                <div>设计之旅</div>
            </div>
            <div v-if="mapStore.showLevel != 4 && mapStore.showLevel != 5 && mapStore.mapScale > 1" style="text-align: center; float: left">
                <img @click="resetMap" style="width: 50px; object-fit: contain; pointer-events: auto; font-size: 0" src="@/assets/imgs/icon_reset.png" alt="" />
                <div>一键还原</div>
            </div>
            <div
                v-if="mapStore.showLevel == 4"
                @click="openAnimation"
                style="width: 180px; height: 36px; line-height: 40px; pointer-events: auto; text-align: center; float: left; position: relative; margin-top: 25px"
            >
                <img style="width: 170px; height: 36px; position: absolute; top: 0; left: 0; z-index: 1" src="@/assets/imgs/bg_road_no.png" alt="" />
                <img style="position: relative; z-index: 2; width: 22px; vertical-align: sub; object-fit: contain; font-size: 0" src="@/assets/imgs/icon_road_no.png" alt="" />
                <span style="margin-left: 5px; position: relative; z-index: 2">9天设计之旅</span>
            </div>
            <div
                v-if="mapStore.showLevel == 5"
                style="width: 200px; height: 45px; line-height: 48px; pointer-events: auto; text-align: center; float: left; position: relative; margin-top: 25px"
            >
                <img style="width: 190px; height: 45px; position: absolute; top: 0; left: 0; z-index: 10" src="@/assets/imgs/bg_road_yes.png" alt="" />
                <img style="position: relative; z-index: 11; width: 24px; vertical-align: sub; object-fit: contain; font-size: 0" src="@/assets/imgs/icon_road_yes.png" alt="" />
                <span style="margin-left: 5px; position: relative; z-index: 11; color: white">9天设计之旅</span>
            </div>
            <div style="text-align: center; float: right; position: relative">
                <div style="position: relative; width: 50px; height: 50px; display: inline-block">
                    <div
                        v-if="mapStore.showLevel == 5"
                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;pointer-events: auto; border-radius: 50%; background-color: rgba(0, 0, 0, 0.5); z-index: 2"
                    ></div>
                    <img @click="contact.open()" style="width: 50px; object-fit: contain; pointer-events: auto; font-size: 0" src="@/assets/imgs/icon_contact.png" alt="" />
                </div>
                <div>联系我们</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { getAssetsFile } from '@/utils/assets';
// import { bgMusicPlayOuPause } from '@/utils/audio.js';
import { productType, productName1, productName2, productName3, productName4, amberTypeData, amberNameData } from '@/api/productData.js';
import useMapStore from '@/store/map';
import delay from '@/utils/tools/delay.js';
import Map from '@/components/map.vue';
import Channel from '@/components/channel.vue';
import contact from '@/components/global/contact';
import { pv, ev } from '@/utils/tools/track.js';
const mapStore = useMapStore();
const delayFlag = ref(false);
const selectType = ref(0);
const selectValue = ref('0');
// 放大地图层级
async function addMapScale() {
    if (delayFlag.value) return;
    delayFlag.value = true; // 打开延时开关
    switch (mapStore.showLevel) {
        case 1:
            mapStore.mapScale = 7;
            mapStore.showLevel = 2;
            break;
        // case 2:
        //     mapStore.mapScale = 7;
        //     mapStore.showLevel = 3;
        //     break;
        default:
            break;
    }
    await delay(0.5);
    delayFlag.value = false; // 关闭延时开关
}
// 减小地图层级
async function reduceMapScale() {
    if (delayFlag.value) return;
    delayFlag.value = true; // 打开延时开关
    switch (mapStore.showLevel) {
        case 2:
            mapStore.mapScale = 1;
            mapStore.showLevel = 1;
            mapStore.mapX = 0;
            mapStore.mapY = 0;
            break;
        // case 3:
        //     mapStore.mapScale = 4;
        //     mapStore.showLevel = 2;
        //     break;
        default:
            break;
    }
    await delay(0.5);
    delayFlag.value = false; // 关闭延时开关
}
// 还原地图位置
function resetMap() {
    mapStore.mapScale = 1;
    mapStore.showLevel = 1;
    mapStore.mapX = 0;
    mapStore.mapY = 0;
}
function openAnimation() {
    mapStore.mapScale = 2.2;
    mapStore.mapX = 30;
    mapStore.mapY = 80;
    mapStore.isPlayAnimation = true
    // 打开路线动画
    mapStore.currentPage = 'animation';
    mapStore.showLevel = 5; // 显示动画层级
    pv('idm_RoadLineAnimation');
}
function openRoadLine() {
    mapStore.currentPage = 'loadLine';
    selectType.value = 0;
    selectValue.value = '0';
    mapStore.showLevel = 4;
    mapStore.mapScale = 2.2;
    mapStore.mapX = 30;
    mapStore.mapY = 80;
    pv('idm_staticRoadLine');
}
// 返回上一步
function backLast() {
    if (mapStore.currentPage == 'loadLine') {
        resetMap(); // 返回到初始页面
        mapStore.currentPage = 'init';
    } else if (mapStore.currentPage == 'animation') {
        mapStore.showLevel = 4; // 打开静态路线页面
        mapStore.currentPage = 'loadLine';
        mapStore.isPlayAnimation = false;
    }
}

watch(
    () => ({
        productType: selectType.value,
    }),
    (newValues, oldValues) => {
        mapStore.productType = selectType.value;
        selectValue.value = '0';
        ev('idm_main', 'idm_button_type:' + amberTypeData[selectType.value]);
    },
);
watch(
    () => ({
        productName: selectValue.value,
    }),
    (newValues, oldValues) => {
        mapStore.productName = selectValue.value;
        ev('idm_main', 'idm_button_productName:' + amberNameData[selectValue.value]);
    },
);
</script>

<style></style>
