<template>
    <div class="base-dialog-wrapper" :class="$style['start-page-wrapper']">
        <div :class="$style['start-content']">
            <div :class="$style['start_bg']">
                <img style="width: 100%; height: 100%" src="@/assets/imgs/bg_start.png" alt="" />
            </div>
            <div style="position: relative; z-index: 2; text-align: center">
                <img style="width: 40%; transform: translateX(10%)" src="@/assets/imgs/title.jpg" alt="" />
                <div style="width: 100%; margin-top: 10px; padding: 0 20px; text-align: left; font-family: HarmonyOS; font-size: 14px">
                    <p>
                        “When lt Comes to Design, Nobody Does lt Like the ltalians. ltaly remained, for centuries, one of the most innovative places for interiors and
                        architecture.
                    </p>
                    <p style="margin-top: 10px">And we will always be ready to welcome you.”</p>
                    <p style="text-align: right; font-size: 16px; margin-top: 30px">Augusto Di Giacinto</p>
                    <p style="font-size: 12px; text-align: right; color: #51594d">Trade Commissioner of ITA Shanghai</p>
                </div>
                <div style="text-align: center; margin: 40px 0 50px">
                    <button @click="onClose" :class="$style['enterBtn']">
                        开启意大利设计之旅 <img style="width: 20px; vertical-align: sub" src="@/assets/imgs/icon_enter.png" alt="" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import useMapStore from '@/store/map';
import tips from '@/components/global/tips';
import { ev } from '@/utils/tools/track.js';
const mapStore = useMapStore();

const props = defineProps({
    close: {
        type: Function,
    },
});

function onClose() {
    props.close();
    mapStore.enter = true;
    tips.open();
    ev('idm_start', 'idm_button_enter'); // 百度按钮事件埋点
}
</script>

<style lang="scss" module>
@import '@/styles/themes.scss';

.start-page-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.start-content {
    position: relative;
    width: 90%;
    // height: 90%;
    padding: 40px 0 0 0;
    border-radius: 0.3rem;
    pointer-events: auto;
    color: black;
}
.start_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
}
.enterBtn {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    letter-spacing: 1px;
    font-size: 16px;
    border: none;
    color: white;
    border-radius: 20px;
    background-image: url('@/assets/imgs/bg_enterBtn.png');
    background-size: 100% 100%;
}
</style>
