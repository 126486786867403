import {
  Button,
  Icon,
  Loading,
  DropdownMenu,
  DropdownItem,
  Image as VanImage,
} from "vant";

export default (vue3App) => {
  vue3App.use(Button);
  vue3App.use(Icon);
  vue3App.use(Loading);
  vue3App.use(VanImage);
  vue3App.use(DropdownMenu);
  vue3App.use(DropdownItem);
};
