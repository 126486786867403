/* eslint-disable no-unused-vars */
/* jshint esversion: 6 */
import router from '../../router';
import Cookies from 'js-cookie';
import { min } from 'lodash';

import _object from 'lodash/object';

export const objCompact = (object) => {
    return _object.omitBy(object, (item) => {
        return item === '';
    });
};
export const delay = (time) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({
                success: true,
            });
        }, time * 1000);
    });
};

export const formatValueColor = (val, maxVal) => {
    let _val = val || 0.001;
    let _maxVal = maxVal || 10000;

    if (_val > _maxVal) {
        _val = _maxVal;
    }
    const progress = _val / _maxVal;
    if (progress < 0.2) {
        return '#17FF79';
    } else if (progress >= 0.2 && progress < 0.66) {
        return '#ff7c00';
    } else {
        return '#ff0000';
    }
};

/**
 * hex to rgb
 */
export const hexToRgba = (hexColor, opacity = 1) => {
    return hexColor.replace(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i, (match, r, g, b) => {
        return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}, ${opacity})`;
    });
};

/**
 * 格式化时间 formatVantNowDate
 */
export const formatVantNowDate = () => {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return year + '/' + month + '/' + day;
};

/**
 * 格式化时间 formatVantNowTime
 */
export const formatVantNowTime = () => {
    var date = new Date();
    var _hours = date.getHours();
    var hours = _hours < 10 ? '0' + _hours : _hours + '';
    var _minutes = date.getMinutes();
    var minutes = _minutes < 10 ? '0' + _minutes : _minutes + '';

    return [hours, minutes];
};

export const unique = (arr) => {
    return Array.from(new Set(arr));
};

export const maxByArr = (arr) => {
    return arr.reduce((num1, num2) => {
        const value = num1 > num2 ? num1 : num2;
        return Math.round(value);
    });
};
export const minByArr = (arr) => {
    if (arr.length < 1) return 0;
    return arr.reduce((num1, num2) => {
        const value = num1 * 1 < num2 * 1 ? num1 : num2;
        return Math.round(value);
    });
};

export const getMaxValueByMapData = (arr, valueKey = 'value') => {
    if (arr.length < 1) {
        return 1000;
    } else {
        return Math.round(arr[0][valueKey]) || 10000;
    }
};

export const getResetScale = () => {
    return new Promise((resolve, reject) => {
        const window_w = document.body.clientWidth;
        const window_h = document.body.clientHeight;
        const scalePre = window_w / window_h;

        let scale = 1;
        let top = 0;
        let left = 0;

        if (scalePre > 1.777777777777777) {
            scale = window_h / 2160;
            const w = 3840 * scale;
            left = (window_w - w) / 2;
            top = 0;
        } else {
            scale = window_w / 3840;
            const h = 2160 * scale;
            top = (window_h - h) / 2;
            left = 0;
        }

        return resolve({
            scale: scale,
            top: top,
            left: left,
        });
    });
};

export const splitArr = (arr, num = 8) => {
    let result = [];
    for (var i = 0; i < arr.length; i += num) {
        result.push(arr.slice(i, i + num));
    }
    return result;
};

/**
 * 网站地址获取指定query id
 * @param {*} name
 * @returns
 */
export const getQueryStringByName = (name) => {
    var result = location.search.match(new RegExp('[?&]' + name + '=([^&]+)', 'i'));
    if (result == null || result.length < 1) {
        return '';
    }
    return result[1];
};

export const strToJson = (str) => {
    try {
        return JSON.parse(str);
    } catch (error) {
        return null;
    }
};

/**
 * 是否微信
 * @returns
 */
export const getIsWxClient = () => {
    var ua = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == 'micromessenger';
};

/**
 * 判断是否ios
 * @returns
 */
export const isIPhone = () => {
    var u = navigator.userAgent;
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    return isIOS;
};

/**
 * 获取路由的query
 */
export const queryURLParams = (url) => {
    let pattern = /(\w+)=(\w+)/gi; //定义正则表达式
    let parames = {}; // 定义参数对象
    url.replace(pattern, ($, $1, $2) => {
        parames[$1] = $2;
    });
    if (Object.keys(parames).length) {
        return parames;
    } else null;
};

/**
 * 获取路由的query
 */
export const mapQueryURLParams = (url, queryStr) => {
    let pattern = /(\w+)=(\w+)/gi; //定义正则表达式
    let parames = {}; // 定义参数对象
    url.replace(pattern, ($, $1, $2) => {
        parames[$1] = $2;
    });
    if (Object.keys(parames).length) {
        return url + '&' + queryStr;
    } else {
        return url + '?' + queryStr;
    }
};

/**
 * js修改当前页面地址栏参数
 * @param {*} url
 * @param {*} arg
 * @param {*} arg_val
 * @returns
 */
export const changeURLArg = (url, arg, arg_val) => {
    var pattern = arg + '=([^&]*)';
    var replaceText = arg + '=' + arg_val;
    if (url.match(pattern)) {
        var tmp = '/(' + arg + '=)([^&]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
    } else {
        if (url.match('[?]')) {
            return url + '&' + replaceText;
        } else {
            return url + '?' + replaceText;
        }
    }
};

/**
 * 删除URL中指定search参数,会将参数值一起删除
 * @param {string} url 地址字符串
 * @param {array} aParam 要删除的参数key数组，如['name','age']
 * @return {string} 返回新URL字符串
 */
export const ridUrlParam = (url, params) => {
    for (var index = 0; index < params.length; index++) {
        var item = params[index];
        var fromIndex = url.indexOf(item + '='); //必须加=号，避免参数值中包含item字符串
        if (fromIndex !== -1) {
            // 通过url特殊符号，计算出=号后面的的字符数，用于生成replace正则
            var startIndex = url.indexOf('=', fromIndex);
            var endIndex = url.indexOf('&', fromIndex);
            var hashIndex = url.indexOf('#', fromIndex);
            var num;
            var reg = '';
            if (endIndex !== -1) {
                // 后面还有search参数的情况
                num = endIndex - startIndex;
                reg = new RegExp(item + '=.{' + num + '}');
                url = url.replace(reg, '');
            } else if (hashIndex !== -1) {
                // 有hash参数的情况
                num = hashIndex - startIndex - 1;
                reg = new RegExp('&?' + item + '=.{' + num + '}');
                url = url.replace(reg, '');
            } else {
                // search参数在最后或只有一个参数的情况
                reg = new RegExp('&?' + item + '=.+');
                url = url.replace(reg, '');
            }
        }
    }
    var noSearchParam = url.indexOf('=');
    if (noSearchParam === -1) {
        url = url.replace(/\?/, ''); // 如果已经没有参数，删除？号
    }
    return url;
};

/**
 * 身份证号精确计算年龄
 * @param {*} IDCard 18位身份证
 * @returns
 */
export const analyzeIDCard = (IDCard) => {
    //获取用户身份证号码
    const userCard = IDCard;
    //如果身份证号码为undefind则返回空
    if (!userCard) {
        return false;
    }
    //获取出生年月日
    const yearBirth = userCard.substring(6, 10);
    const monthBirth = userCard.substring(10, 12);
    const dayBirth = userCard.substring(12, 14);
    //获取当前年月日并计算年龄
    const myDate = new Date();
    const monthNow = myDate.getMonth() + 1;
    const dayNow = myDate.getDate();
    let age = myDate.getFullYear() - yearBirth;
    if (monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)) {
        age--;
    }
    if (age >= 18) {
        return true;
    }
    return false;
};

/**
 * 直接读成blob文件对象
 * @param {*} url
 * @param {*} imgId
 */
export const getImage = (url, imgId) => {
    var xhr = new XMLHttpRequest();
    xhr.open('get', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
        if (this.status == 200) {
            document.getElementById(imgId).src = URL.createObjectURL(this.response);
        }
    };
    xhr.send(null);
};

/**
 * 限制字符串长度多余...
 */
export const limitedStr = (arg, length) => {
    let str = '';
    if (arg.length <= length) {
        str = arg;
    } else {
        for (let i = 0; i < length; i++) {
            const element = arg.charAt(i);
            str += element;
        }
        str += '...';
    }
    return str;
};

/**
 * 检查字符串是否包含 http和www
 * @param {*} valueStr
 * @returns
 */
export const httpFilter = (valueStr) => {
    if (valueStr != '' && valueStr != null) {
        if (valueStr.indexOf('www') >= 0 || valueStr.indexOf('http') >= 0 || valueStr.indexOf('https') >= 0) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

/**
 * 后缀名判断
 * @param {*} str
 * @returns
 */
export const suffixName = (str) => {
    const suffix = str.substring(str.lastIndexOf('.') + 1).toLowerCase();

    const suffixLists = [
        {
            suffix: 'png',
            type: 'image',
        },
        {
            suffix: 'jpg',
            type: 'image',
        },
        {
            suffix: 'jpeg',
            type: 'image',
        },
        {
            suffix: 'svg',
            type: 'image',
        },
        {
            suffix: 'bmp',
            type: 'image',
        },
        {
            suffix: 'mp4',
            type: 'video',
        },
        {
            suffix: 'mv4',
            type: 'video',
        },
        {
            suffix: 'webm',
            type: 'video',
        },
        {
            suffix: 'flv',
            type: 'video',
        },
        {
            suffix: 'mp3',
            type: 'audio',
        },
        {
            suffix: 'ogg',
            type: 'audio',
        },
        {
            suffix: 'wav',
            type: 'audio',
        },
    ];
    const result = suffixLists.find((item) => {
        return item.suffix == suffix;
    });

    return (
        result || {
            suffix: suffix,
            type: 'other',
        }
    );
};

/**
 * object key 排序
 * @param {*} obj
 * @returns 返回排好序的新对象
 */
export const objKeySort = (obj) => {
    // 如果要倒序排列，只需把newkey这个数组中的每一项进行颠倒即可，即var newkey = Object.keys(obj).sort().reverse();
    const newkey = Object.keys(obj).sort(); // 先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
    let newObj = {}; // 创建一个新的对象，用于存放排好序的键值对
    for (let i = 0; i < newkey.length; i++) {
        // 遍历newkey数组
        newObj[newkey[i]] = obj[newkey[i]]; // 向新创建的对象中按照排好的顺序依次增加键值对
    }
    return newObj;
};

/**
 * 对象 to string
 * @param {*} obj
 * @returns
 */
export const objToString = (obj) => {
    let str = '';
    _object.mapKeys(obj, (value, key) => {
        str += '&' + key + '=' + value;
    });
    return str;
};

/**
 * 签名方式
 * @param {*} nonceStr 随机数
 * @param {*} timestamp 时间戳
 * @param {*} value 参数转换字符串
 * @param {*} key 秘钥
 * @returns
 */
export const signKey = (nonceStr, timestamp, value, key = 'CwH3k7DqYiShWFgdx8eZBIzMn0X69uGN') => {
    // 第一步：对参数按照key=value的格式，并按照参数名ASCII字典序排序如下
    // stringA="nonceStr=wFGj8Nrc3L5QaZnd&playerId=1&timestamp=1654856427";
    const stringA = nonceStr + value + '&timestamp=' + timestamp;
    // stringSignTemp=stringA+"&key=MJwnhDwmc09ZpUKZ8wBglY37wVIuvifE" 密钥key
    const stringSignTemp = stringA + '&key=' + key;
    return stringSignTemp;
};

/**
 * 随机生成字符串
 * @param {*} len 指定生成字符串长度
 * @returns
 */
export const getRandomString = (len) => {
    let _charStr = 'abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789',
        min = 0,
        max = _charStr.length - 1,
        _str = ''; //定义随机字符串 变量
    //判断是否指定长度，否则默认长度为15
    len = len || 15;
    //循环生成字符串
    for (var i = 0, index; i < len; i++) {
        index = ((randomIndexFunc, i) => {
            return randomIndexFunc(min, max, i, randomIndexFunc);
        })((min, max, i, _self) => {
            let indexTemp = Math.floor(Math.random() * (max - min + 1) + min),
                numStart = _charStr.length - 10;
            if (i == 0 && indexTemp >= numStart) {
                indexTemp = _self(min, max, i, _self);
            }
            return indexTemp;
        }, i);
        _str += _charStr[index];
    }
    return _str;
};

export const uid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

/**
 * 判断ios 系统版本是不是 15.4
 */
export const isIso15_4 = () => {
    var str = navigator.userAgent.toLowerCase();
    var ver = str.match(/cpu iphone os (.*?) like mac os/);
    return new Promise((resolve, reject) => {
        if (ver && ver[1].replace(/_/g, '.') == '15.4') {
            resolve({
                success: true,
                msg: '你的版本为15.4，不支持3D渲染。 建议去升级之后再体验！',
            });
        } else {
            reject({
                success: false,
                msg: '您的系统支持体验',
            });
        }
    });
};

/**
 * 手机掩码
 * @param {*} arg +8613162049469
 * @returns
 */
export const maskMobile = (arg) => {
    const arr = arg.split('');
    for (let index = arr.length - 5; index > arr.length - 9; index--) {
        arr[index] = '*';
    }
    const str = arr.toString();
    const res = str.replace(/,/g, '');
    return res;
};

/**
 * 手机掩码
 * @param {*} arg 13162049469
 * @returns
 */
export const generateMixCode = (n) => {
    const chars = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
    ];
    var a = '';
    for (var i = 0; i < n; i++) {
        a += chars[Math.ceil(Math.random() * 35)];
    }
    return a;
};

export const openWindow = (str) => {
    const steHead = str.substring(0, 4);
    if (steHead === 'http') {
        window.open(str);
    } else {
        router.replace(str);
    }
};

/**
 * 判断是否登录
 * @param {*} str
 */
export const isLogin = () => {
    const auth_token = Cookies.get('auth_token');
    return new Promise((resolve, reject) => {
        if (!auth_token) {
            reject(0);
        } else {
            resolve(1);
        }
    });
};

/**
 * 获取时间戳
 * @returns
 */
export const getTime = () => {
    var time = new Date().getTime().toString();
    var b = 0;
    for (var i = 0; i < time.length - 1; i++) {
        b += Number(time[i]);
    }
    var ntime = time.substring(0, time.length - 1) + (b % 10);
    return ntime;
};
/**
 * 获取当前格式化后的时间: 2023年8月15日 16:04:25
 * @returns
 */
export const getNowTime = () => {
    var date = new Date();
    var _hours = date.getHours();
    var hours = _hours < 10 ? '0' + _hours : _hours + '';
    var _minutes = date.getMinutes();
    var minutes = _minutes < 10 ? '0' + _minutes : _minutes + '';
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var seconds = date.getSeconds();
    var time = year + '年' + month + '月' + day + '日 ' + hours + ':' + minutes + ':' + seconds;
    return time;
};

/**
 * Amber 埋点 时间戳
 * @param {*} newDate
 * @returns
 */
export const emberTimeStamp = (newDate = new Date()) => {
    var date = newDate;
    var year = date.getFullYear();
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
};
