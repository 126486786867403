import { createApp } from 'vue';
import '@/styles/global.scss';
import 'animate.css';
import store from './store';
import App from './App.vue';
const vue3App = createApp(App);
vue3App.use(store);
import initGlobalProperties from '@/utils/globalProperties.js';
initGlobalProperties(vue3App);

import router from './router';
import initVant from '@/utils/vant.js';
import '@vant/touch-emulator';
initVant(vue3App);


import VConsole from 'vconsole';
if (import.meta.env.MODE == 'development') {
    new VConsole();
}

vue3App.use(router).mount('#app');
