const delay = (time) => {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve({
				success: true,
			});
		}, time * 1000);
	});
};

export default delay;
