<template>
    <div class="base-dialog-wrapper" :class="$style['start-page-wrapper']">
        <div :class="$style['start-content']">
            <div style="position: absolute; bottom: -60px; left: 50%; transform: translateX(-50%)">
                <img @click="onClose" style="width: 40px" src="@/assets/imgs/icon_close.png" alt="" />
            </div>
            <div :class="$style['start_bg']">
                <img style="width: 100%; height: 100%" src="@/assets/imgs/bg_content.png" alt="" />
            </div>
            <div style="position: relative; padding: 0 20px; font-family: HarmonyOS; z-index: 2">
                <div style="font-size: 24px; padding-bottom: 15px">{{ params.name }}</div>
                <div style="margin-bottom: 15px; border-radius: 10px; overflow: hidden">
                    <img :src="getAssetsFile('productImg/' + params.id + '.jpg')" style="width: 100%;max-height: 200px; object-fit: contain" alt="" />
                </div>
                <div style="display: flex; padding-bottom: 15px">
                    <div style="display: flex; align-items: center; height: 40px; margin-right: 10px">
                        <img
                            style="width: 35px; height: 35px; object-fit: contain; border-radius: 18px; box-shadow: 0px 0px 5px 0px #137d3b"
                            src="@/assets/imgs/icon_web.png"
                            alt=""
                        />
                    </div>
                    <div style="width: 1px;height: 35px;margin-right: 10px; background-color: black;"></div>
                    <div style="display: flex; flex-direction: column; justify-content: space-evenly; height: 40px; font-size: 14px">
                        <p style="color: #808080">网址</p>
                        <p><a :href="'https://'+params.web" style="text-decoration: underline; color: #34558a" target="_blank">{{ params.web }}</a></p>
                    </div>
                </div>
                <div style="display: flex; padding-bottom: 15px">
                    <div style="display: flex; align-items: center; height: 40px; margin-right: 10px">
                        <img
                            style="width: 35px; height: 35px; object-fit: contain; border-radius: 18px; box-shadow: 0px 0px 5px 0px #137d3b"
                            src="@/assets/imgs/icon_address.png"
                            alt=""
                        />
                    </div>
                    <div style="width: 1px;height: 35px;margin-right: 10px; background-color: black;"></div>
                    <div style="display: flex; flex-direction: column; justify-content: space-evenly; height: 40px; font-size: 14px">
                        <p style="color: #808080">地址</p>
                        <p>{{ params.address }}</p>
                    </div>
                </div>
                <div style="display: flex; margin-bottom: 15px">
                    <div style="display: flex; align-items: center; height: 40px; margin-right: 10px">
                        <img
                            style="width: 35px; height: 35px; object-fit: contain; border-radius: 18px; box-shadow: 0px 0px 5px 0px #137d3b"
                            src="@/assets/imgs/icon_tel.png"
                            alt=""
                        />
                    </div>
                    <div style="width: 1px;height: 35px;margin-right: 10px; background-color: black;"></div>
                    <div style="display: flex; flex-direction: column; justify-content: space-evenly; height: 40px; font-size: 14px">
                        <p style="color: #808080">电话</p>
                        <p>{{ params.tel }}</p>
                    </div>
                </div>
                <div style="display: flex; margin-bottom: 20px">
                    <div style="display: flex; align-items: center; height: 40px; margin-right: 10px">
                        <img
                            style="width: 35px; height: 35px; object-fit: contain; border-radius: 18px; box-shadow: 0px 0px 5px 0px #137d3b"
                            src="@/assets/imgs/icon_email.png"
                            alt=""
                        />
                    </div>
                    <div style="width: 1px;height: 35px;margin-right: 10px; background-color: black;"></div>
                    <div style="display: flex; flex-direction: column; justify-content: space-evenly; height: 40px; font-size: 14px">
                        <p style="color: #808080">邮箱</p>
                        <p><a :href="'mailto:'+params.email" style="text-decoration: underline; color: #34558a">{{ params.email }}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { getAssetsFile } from '@/utils/assets';
const props = defineProps({
    close: {
        type: Function,
    },
    params: {
        type: Object,
    },
});

function onClose() {
    props.close();
}
</script>

<style lang="scss" module>
@import '@/styles/themes.scss';

.start-page-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.start-content {
    position: relative;
    width: 90%;
    // height: 90%;
    padding: 40px 0 0 0;
    border-radius: 0.3rem;
    pointer-events: auto;
    color: black;
}
.start_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
}
.enterBtn {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    letter-spacing: 1px;
    font-size: 16px;
    border: none;
    color: white;
    border-radius: 20px;
    background-image: url('@/assets/imgs/bg_enterBtn.png');
    background-size: 100% 100%;
}
</style>
