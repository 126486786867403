<template>
    <div style="position: absolute; top: 525px; left: 700px; z-index: 101">
        <div>
            <div id="lottieBox" class="animation" style="pointer-events: auto"></div>
        </div>
    </div>
</template>

<script setup>
import useMapStore from '@/store/map';
import { onMounted, onUnmounted } from 'vue';
import intro from '@/components/global/intro';
import lottie from 'lottie-web';
import * as animationData from '@/assets/animation/data.json';
const mapStore = useMapStore();
let animation = {};
function animationLoaded() {
    intro.open();
}
onMounted(() => {
    // mapStore.mapScale = 2.2;
    // mapStore.mapX = 30;
    // mapStore.mapY = 80;
    animation = lottie.loadAnimation({
        container: document.getElementById('lottieBox'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData.default,
    });
    // animation.setSpeed(1); // 播放速度
    animation.addEventListener('complete', animationLoaded);
});
watch(
    () => ({
        isPlayAnimation: mapStore.isPlayAnimation,
    }),
    (newValues, oldValues) => {
        if (mapStore.isPlayAnimation == true) {
            animation.play();
        } else {
            animation.stop();
        }
    },
);
onUnmounted(() => {
    animation.destroy();
});
</script>

<style>
.animation {
    width: 2395px;
    height: 100%;
    object-fit: contain;
}
</style>
