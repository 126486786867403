<template>
    <div id="fatherBox" style="width: 100%; height: 100%; overflow: hidden">
        <div
            id="map"
            ref="scaleBox"
            :style="{
                transform: `scale(${mapStore.mapScale}) translate(${mapStore.mapX}px, ${mapStore.mapY}px)`,
                transition: 'transform 0.15s',
            }"
            style="position: relative; width: 100%; height: 100%"
        >
            <div id="mapBox" style="position: relative; width: 100%; height: 100%">
                <div
                    style="position: absolute; top: 50%; left: 50%; width: 3750px; height: 7500px"
                    :style="{
                        transform: `translate(-50%,-50%) scale(${initScale})  `,
                    }"
                >
                    <div
                        v-if="mapStore.showLevel == 5"
                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 9; pointer-events: auto; background-color: rgba(0, 0, 0, 0.5)"
                    ></div>
                    <img style="display: block; font-size: 0; width: 100%; height: 100%" src="@/assets/imgs/map.svg" />
                    <!-- 第一层级：区域图 -->
                    <!-- <LevelOne v-if="mapStore.showLevel == 1 && mapStore.productType == 0"></LevelOne> -->
                    <!-- 第二层级：标点图 -->
                    <LevelTwo v-if="mapStore.showLevel == 1 && mapStore.productType == 0"></LevelTwo>
                    <!-- 第一层级：图标图 -->
                    <LevelIcon v-if="mapStore.showLevel == 1 && mapStore.productType == 0"></LevelIcon>
                    <!-- 第三层级：具体图 -->
                    <LevelThree v-if="mapStore.showLevel == 2 || mapStore.productType != 0"></LevelThree>
                    <!-- 第四层级：路线图 -->
                    <LevelFour v-show="mapStore.showLevel == 4"></LevelFour>
                    <!-- 第五层级：路线动画 -->
                    <LevelFive v-show="mapStore.showLevel == 5"></LevelFive>
                    <!-- 第六层级: 路线可点击 -->
                    <LevelSix v-if="mapStore.showLevel == 4"></LevelSix>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Hammer from 'hammerjs';
import useMapStore from '@/store/map';
// import Channel from '@/components/channel.vue';
// import LevelOne from '@/components/levelOne.vue';
import LevelIcon from '@/components/levelIcon.vue';
import LevelTwo from '@/components/levelTwo.vue';
import LevelThree from '@/components/levelThree.vue';
import LevelFour from '@/components/levelFour.vue';
import LevelFive from '@/components/levelFive.vue';
import LevelSix from '@/components/levelSix.vue';
const mapStore = useMapStore();

const mc = ref(null);
const scaleBox = ref(null);
const initScale = ref(1);

function initdata() {
    mc.value = new Hammer(scaleBox.value);
    let lastScale = 1;
    let lastPosX = 0;
    let lastPosY = 0;
    let fatherBox = document.getElementById('fatherBox');
    mc.value.get('pinch').set({ enable: true });
    mc.value.get('pan').set({ direction: Hammer.DIRECTION_ALL });

    // 双指缩放
    mc.value.on('pinch', (ev) => {
        if (mapStore.showLevel == 4 || mapStore.showLevel == 5) {
            return; // 如果地图页面，禁止缩放
        }
        if (ev.scale !== 1) {
            mapStore.mapScale = lastScale * ev.scale >= 7 ? 7 : lastScale * ev.scale;
        }
        if (mapStore.mapScale <= 1) {
            mapStore.mapScale = 1;
        }
    });

    // 缩放结束
    mc.value.on('pinchend', () => {
        if (mapStore.showLevel == 4 || mapStore.showLevel == 5) {
            return; // 如果地图页面，禁止缩放
        }
        if (mapStore.mapScale <= 1) {
            mapStore.mapX = 0;
            mapStore.mapY = 0;
            return;
        }
        lastScale = mapStore.mapScale;
        if (mapStore.mapScale <= 3.5) {
            mapStore.showLevel = 1; // 显示第一层级
        } else if (mapStore.mapScale <= 7) {
            mapStore.showLevel = 2; // 显示第二层级
        }
        // else if (mapStore.mapScale <= 7) {
        //     mapStore.showLevel = 3; // 显示第三层级
        // }
    });

    // 拖拽移动
    mc.value.on('pan', (ev) => {
        if (mapStore.showLevel == 4 || mapStore.showLevel == 5) {
            return; // 动画层级禁止拖拽移动
        }
        if (mapStore.mapScale <= 1) {
            mapStore.showLevel = 1; // 显示第一层级
            mapStore.mapX = 0;
            mapStore.mapY = 0;
            return;
        }
        let limitX = (fatherBox.clientWidth * (mapStore.mapScale - 1)) / 2 / mapStore.mapScale; // 水平临界值
        let limitY = (fatherBox.clientHeight * (mapStore.mapScale - 1)) / 2 / mapStore.mapScale; // 垂直临界值
        let tempX = lastPosX;
        let tempY = lastPosY;
        tempX += ev.deltaX / mapStore.mapScale; // 调整除数以控制移动速度
        tempY += ev.deltaY / mapStore.mapScale; // 调整除数以控制移动速度
        let x = tempX * tempX; // 即将水平位移
        let y = tempY * tempY; // 即将垂直位移
        if (x >= limitX * limitX || y >= limitY * limitY) {
            return; // 超出边界，退出
        }
        // 减小移动速度
        mapStore.mapX = lastPosX + ev.deltaX / mapStore.mapScale; // 调整除数以控制移动速度
        mapStore.mapY = lastPosY + ev.deltaY / mapStore.mapScale; // 调整除数以控制移动速度
    });

    // 拖拽移动结束
    mc.value.on('panend', () => {
        if (mapStore.showLevel == 4 || mapStore.showLevel == 5) {
            return; // 动画层级禁止拖拽移动
        }
        const box = document.getElementById('map');
        if (mapStore.mapScale <= 1) {
            mapStore.showLevel = 1; // 显示第一层级
            mapStore.mapX = 0;
            mapStore.mapY = 0;
            return;
        }
        lastPosX = mapStore.mapX;
        lastPosY = mapStore.mapY;
    });
}

function countScale() {
    let map = document.getElementById('mapBox');
    let width = map.clientWidth;
    let height = map.clientHeight;
    let boxScale = width / height;
    let imgScale = 0.5;
    if (boxScale > imgScale) {
        initScale.value = height / 7500;
    } else {
        initScale.value = width / 3750;
    }
    // mapStore.mapScale = 4;
    // mapStore.mapX = 30;
    // mapStore.mapY = 80;
    // mapStore.showLevel = 4;
}
onMounted(() => {
    initdata();
    countScale();
});
</script>

<style></style>
