<template>
    <div class="base-dialog-wrapper" :class="$style['start-page-wrapper']">
        <div v-if="false" :class="$style['start-content']">
            <div style="position: absolute; bottom: -60px; left: 50%; transform: translateX(-50%)">
                <img @click="onClose" style="width: 40px" src="@/assets/imgs/icon_close.png" alt="" />
            </div>
            <div :class="$style['start_bg']">
                <img style="width: 100%; height: 100%" src="@/assets/imgs/bg_content.png" alt="" />
            </div>
            <div style="position: relative; padding: 0 20px; font-family: HarmonyOS; z-index: 2">
                <div style="font-size: 24px; padding-bottom: 15px">9天设计之旅</div>
                <div style="margin: 50px auto 20px; width: 200px; height: 200px">
                    <img style="width: 100%; height: 100%; object-fit: contain" src="@/assets/imgs/qrCode.png" alt="" />
                </div>
                <div style="text-align: center; font-size: 18px; font-family: HarmonyOS; margin-bottom: 90px">
                    <p><span>咨询及报名</span><span style="margin-left: 10px">请扫描二维码</span></p>
                    <p style="margin-top: 5px">联系人：范女士</p>
                </div>
            </div>
        </div>
        <div v-else style="width: 100%; height: 100%; overflow: auto">
            <img src="@/assets/imgs/day9.png" alt="" style="width: 100%;object-fit: contain;" />
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import useMapStore from '@/store/map';
const mapStore = useMapStore();
const props = defineProps({
    close: {
        type: Function,
    },
});

function onClose() {
    props.close();
    mapStore.showLevel = 4;
    mapStore.isPlayAnimation = false;
    mapStore.currentPage = 'loadLine';
}
</script>

<style lang="scss" module>
@import '@/styles/themes.scss';

.start-page-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.start-content {
    position: relative;
    width: 90%;
    // height: 90%;
    padding: 40px 0 0 0;
    border-radius: 0.3rem;
    pointer-events: auto;
    color: black;
}
.start_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
}
.enterBtn {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    letter-spacing: 1px;
    font-size: 16px;
    border: none;
    color: white;
    border-radius: 20px;
    background-image: url('@/assets/imgs/bg_enterBtn.png');
    background-size: 100% 100%;
}
</style>
