import { defineStore } from 'pinia';
export default defineStore('map', {
    state: () => {
        return {
            enter: false,
            mapScale: 1, // 地图缩放倍数
            mapX: 0, // 地图水平位移
            mapY: 0, // 地图垂直位移
            showLevel: 1, // 显示地图层级
            productType: 0, // 筛选产品类型
            productName: '0', // 筛选类型1的产品名
            currentPage:'init', // 当前页面状态，用于判断返回上一级到哪一步
            bgAudioPlaying: 0, //背景音乐状态
            isPlayAnimation:false, // 是否加载动画
        };
    },
    getters: {},
    actions: {},
    // 开始数据持久化
    persist: {
        enabled: false,
    },
});
