<template>
    <div>
        <div v-for="(item, index) in roadLineData" :key="'product' + index" style="position: absolute;" :style="{ top: item.top, left: item.left }">
            <div
                v-if="
                    mapStore.productType == 0 ||
                    (mapStore.productType == item.type && mapStore.productName == '0') ||
                    (mapStore.productType == item.type && mapStore.productName == item.typeName)
                "
                @click="selectItem(item)"
                style="padding: 8px 20px; color: black;background-color:rgb(255,0,0); opacity: 0; border-radius: 40px"
            >
                <p style="font-size: 38px">
                    <span :class="'iconfont icon-icon_type' + item.type"></span>
                    <span style="margin-left: 5px">{{ item.type_en }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { roadLineData, colorData } from '@/api/productData.js';
import productDetails from '@/components/global/productDetails';
import useMapStore from '@/store/map';
const mapStore = useMapStore();
const currentItem = ref(null);
function selectItem(val) {
    currentItem.value = val; // 当前选中项
    productDetails.open(val); // 打开详情弹窗
}
</script>

<style></style>
