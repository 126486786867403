import { createRouter, createWebHistory } from 'vue-router';
import homeview from '../views/HomeView.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: homeview,
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.VITE_APP_BASE_URL),
    routes,
});

export default router;
