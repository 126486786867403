/* jshint esversion: 9 */
import Contact from './Contact.vue';
import { createVNode, render } from 'vue';
import { getZIndex } from '@/utils/zIndex';

let mountNode = null;

/**
 * 显示
 * @param {*} option
 * @returns
 */
const open = (params = {}) => {
    if (mountNode) {
        document.getElementById('landspace-wrapper').removeChild(mountNode);
        mountNode = null;
    }

    //将options参数传入，并将Notice组件转换成虚拟DOM，并赋值给app
    const app = createVNode(Contact, {
        close,
        params,
    });

    // 渲染dom
    mountNode = document.createElement('div');
    mountNode.className = 'global-dialog-transparent-75 animate__animated animate__fadeIn';
    mountNode.style = `z-index: ${getZIndex()}; `;
    render(app, mountNode);

    document.getElementById('landspace-wrapper').appendChild(mountNode);
};

/**
 * 删除
 */
const close = () => {
    if (mountNode) {
        render(null, mountNode);
        document.getElementById('landspace-wrapper').removeChild(mountNode);
        mountNode = null;
    }
};

/**
 * 提示
 */
export default {
    open,
    close,
};
