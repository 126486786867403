// 全部产品的数据
export const productData = [
    {
        id: 1,
        name: 'METALSUD LO GATTO SRL',
        web: 'www.ferwall.it/',
        area_zh: '卡拉布里亚',
        area_en: 'Calabria',
        address: 'Zona Industriale Località Aeroporto, 8990 Vibo Valentia (VV)',
        email: 'info@ferwall.it',
        tel: '0039 0963 265872',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '防盗门',
        typeName: 'b1',
        product_en: 'Security doors',
        top: '5054px',
        left: '2785px',
    },
    {
        id: 2,
        name: 'OFFICINA ORAFA',
        web: 'www.officina-orafa.com',
        area_zh: '卡拉布里亚',
        area_en: 'Calabria',
        address: 'Viale J. F. Kennedy, 12 – 88062 Cardinale (CZ)',
        email: 'info@officina-orafa.com',
        tel: '0039 0967430172',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '5028px',
        left: '2960px',
    },
    {
        id: 3,
        name: "D'ELIA DI VITIELLO  ALFONSO",
        web: 'www.deliacompany.it/',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Via E. De Nicola, 15 80059 Torre del Greco (NA)',
        email: 'info@deliacompany.it',
        tel: '0039 081 881 1797',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '4065px',
        left: '2105px',
    },
    {
        id: 4,
        name: 'CIANCIULLO MARMI SRL',
        web: 'www.cianciullo.it',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Via Isaia Rossi 49-5184014 Nocera Inferiore (SA) Italiy',
        email: 'info@cianciullo.it',
        tel: '0039 089301308',
        type: 4,
        type_en: 'Finish material',
        product_zh: '大理石',
        typeName: 'd1',
        product_en: 'Marble',
        top: '4118px',
        left: '2223px',
    },
    {
        id: 5,
        name: 'Calpierre S.R.L.',
        web: 'www.calpierre.it',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Viale Olimpico, 5, 80025 Casandrino NA, Italy',
        email: 'fairs@calpierre.it',
        tel: '0039 081 5055589',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '鞋',
        typeName: 'a2',
        product_en: 'Footwear',
        top: '4118px',
        left: '2320px',
    },
    {
        id: 6,
        name: 'FGM MARMI ',
        web: 'www.marmibizzarro.it/',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Via Lampione, 27 82032 Cerreto Sannita (BN)',
        email: 'info@marmibizzarro.it',
        tel: '0039 0824861583',
        type: 4,
        type_en: 'Finish material',
        product_zh: '大理石',
        typeName: 'd1',
        product_en: 'Marble',
        top: '2692px',
        left: '1328px',
    },
    {
        id: 7,
        name: 'RINALDI GROUP SRL',
        web: 'www.rinaldigroup.com',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Via S. Maria zona P.I.P, 84095 Giffoni Valle Piana SA',
        email: 'info@rinaldigroup.com',
        tel: '0039 089.868706',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '床垫',
        typeName: 'c1',
        product_en: 'Mattresses',
        top: '4102px',
        left: '2371px',
    },
    {
        id: 8,
        name: 'ROSMUNDO DI ROSMUNDO GIARLETTA',
        web: 'www.rosmundo.com',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Corso Giuseppe Garibaldi 90 84025 Eboli (SA)',
        email: 'info@rosmundo.com',
        tel: '0039 3334543263',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '4165px',
        left: '2319px',
    },
    {
        id: 9,
        name: 'RANIERI PIETRA LAVICA ',
        web: 'www.ranierilavastone.com',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Via Vicinale Lava Ranieri snc, 80040 Terzigno (NA)',
        email: 'info@ranierilavastone.com',
        tel: '0039 0815292962',
        type: 4,
        type_en: 'Finish material',
        product_zh: '石材',
        typeName: 'd2',
        product_en: 'Stone products',
        top: '4148px',
        left: '2202px',
    },
    {
        id: 10,
        name: 'CHIARAD. SRL',
        web: 'www.chiaradeconcilio.style',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'VIA ANIELLO FALCONE 32, 80127 Napoli (NA)',
        email: 'info@chiaradeconcilio.style',
        tel: '0039 392 353 8461',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '服饰',
        typeName: 'a3',
        product_en: 'Clothing',
        top: '4022px',
        left: '2141px',
    },
    {
        id: 11,
        name: 'EMME.C S.R.L.',
        web: 'www.russocammei.it',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Centro orafo Oromare Strada Provinciale,22KM 1750 81025 Marcianise(CE)',
        email: 'info@russocammei.it',
        tel: '0039 082301644640',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '4084px',
        left: '2430px',
    },
    {
        id: 12,
        name: 'GRANITI FIANDRE SPA',
        web: 'www.irisceramicagroup.com/',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: 'Via Radici Nord, 112 - 42014 Castellarano (RE) ',
        email: 'wwang@iris-group.it',
        tel: '0039 0536 862111',
        type: 4,
        type_en: 'Finish material',
        product_zh: '瓷砖',
        typeName: 'd3',
        product_en: 'Ceramics',
        top: '2735px',
        left: '1250px',
    },
    {
        id: 13,
        name: 'VIA BIZZUNO SRL',
        web: 'www.viabizzuno.com/en/',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: 'Via Luciano Romagnoli, 10, 40010 Bentivoglio (BO)',
        email: 'l.chinello@viabizzuno.com',
        tel: '0039 0541 787948',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '2709px',
        left: '1492px',
    },
    {
        id: 14,
        name: 'SMEG',
        web: 'www.smeg.it/',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: 'Via Leonardo da Vinci, 4 42016 Guastalla (RE)',
        email: 'smeg@smeg.it',
        tel: '0039 0522 8211',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '家用电器',
        typeName: 'c3',
        product_en: 'Household appliances',
        top: '2618px',
        left: '1369px',
    },
    {
        id: 15,
        name: 'SVADON DONDI SPA',
        web: 'www.dondi.it/it/',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: 'Via Dei Tigli, 2 46019 Viadana (MN) - Italy',
        email: 'web.admin@dondi.it',
        tel: '0039 0375 830121',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '床品',
        typeName: 'c4',
        product_en: 'Bedding',
        top: '2587px',
        left: '1168px',
    },
    {
        id: 16,
        name: 'MAKTRON ENTERPRICES SRL',
        web: 'italiansolutions.com/',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: 'Via Cangelasio Moracchi, 85 43039 Salsomaggiore Terme (PR)',
        email: 'info@italiansolutions.com',
        tel: '0039 0524 571550',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2569px',
        left: '991px',
    },
    {
        id: 17,
        name: 'FLORIM CERAMICHE SPA',
        web: 'www.florim.com/it/',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: ' Via Canaletto 24, 41042 Fiorano Modenese',
        email: 'expo.expo@florim.com',
        tel: '0039 054257111',
        type: 4,
        type_en: 'Finish material',
        product_zh: '瓷砖',
        typeName: 'd3',
        product_en: 'Tile',
        top: '2780px',
        left: '1507px',
    },
    {
        id: 18,
        name: 'TONDELLI SRL',
        web: 'www.lorenzotondelli.com',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: 'Via B. Franklin 5/A 41012, Carpi (MO) ',
        email: 'info@tondelliarredamenti.com',
        tel: '0039 059643928',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2627px',
        left: '1427px',
    },
    {
        id: 19,
        name: 'GEES RECYCLING SRL',
        web: 'www.geesrecycling.com',
        area_zh: '弗留利-威尼斯朱利亚',
        area_en: 'Friuli Venezia Giulia',
        address: 'Via Monte Colombera n. 22 33081 Aviano (PN)',
        email: 'geesrecycling@gmail.com',
        tel: '0039 0434 654183',
        type: 4,
        type_en: 'Finish material',
        product_zh: '环保板材',
        typeName: 'd4',
        product_en: 'Panel',
        top: '2114px',
        left: '1659px',
    },
    {
        id: 20,
        name: 'SNAIDERO RINO',
        web: 'www.snaidero.it',
        area_zh: '弗留利-威尼斯朱利亚',
        area_en: 'Friuli Venezia Giulia',
        address: 'Viale Rino Snaidero Cavaliere del Lavoro, 15 33030 Majano Udine (Italy)',
        email: 'rdreolini@snaidero.it',
        tel: '0039 00153510300',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '橱柜',
        typeName: 'b3',
        product_en: 'Kitchen Furniture',
        top: '2090px',
        left: '1862px',
    },
    {
        id: 21,
        name: 'KERASAN',
        web: 'www.kerasan.it/',
        area_zh: '拉齐奥',
        area_en: 'Lazio',
        address: 'Via Albert Einstein, 301033 Civita Castellana (VT)',
        email: 'kerasan@kerasan.it',
        tel: '0039 0761 540627',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '洁具',
        typeName: 'c5',
        product_en: 'Sanitary Appliances',
        top: '3652px',
        left: '1678px',
    },
    {
        id: 22,
        name: 'TENDATECH',
        web: 'www.artendis.com/',
        area_zh: '拉齐奥',
        area_en: 'Lazio',
        address: 'Strada della Neve, 211, 00018 Palombara Sabina (RM)',
        email: 'LORIS@ARTENDIS.COM',
        tel: '0039 0774 630730',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '窗帘',
        typeName: 'c6',
        product_en: 'Curtains',
        top: '3681px',
        left: '1771px',
    },
    {
        id: 23,
        name: 'LOGOPROJECT DESIGN',
        web: 'www.logoproject.it/en/',
        area_zh: '拉齐奥',
        area_en: 'Lazio',
        address: 'Via Salaria, 267, 00199 Roma (RM)',
        email: 'b.tolomei@logoproject.it',
        tel: '0039 0695 479068',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '眼镜',
        typeName: 'a4',
        product_en: 'Eyewear',
        top: '3730px',
        left: '1746px',
    },
    {
        id: 24,
        name: 'SLAMP SPA',
        web: 'www.slamp.com',
        area_zh: '拉齐奥',
        area_en: 'Lazio',
        address: 'Via Vaccareccia, 14, 00071 Pomezia (RM)',
        email: 'info@slamp.it',
        tel: '0039 06 916 2391',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '3796px',
        left: '1717px',
    },
    {
        id: 25,
        name: 'FLOEMA SRL',
        web: 'www.floema.it',
        area_zh: '拉齐奥',
        area_en: 'Lazio',
        address: 'Via XX Settembre, 118 00184 ROMA, Italia',
        email: 'info@floema.it ',
        tel: '0039 06 622 89 727',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具、板材、木门',
        typeName: 'b4',
        product_en: 'Furniture,Panel,Door',
        top: '3046px',
        left: '1749px',
    },
    {
        id: 26,
        name: 'BITICINO SPA',
        web: 'www.bticino.com/',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Viale Luigi Borri, 231, 21100 Varese (VA)',
        email: 'export.bticino@bticino.com.',
        tel: '0039 0332 272111',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '开关系统',
        typeName: 'c7',
        product_en: 'Electric Systems',
        top: '2230px',
        left: '891px',
    },
    {
        id: 27,
        name: 'SICIS',
        web: 'www.sicis.com/',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via Borgonuovo, 29 – 20121 Milano (MI)',
        email: 'info@sicis.com',
        tel: '0039 0544 469711',
        type: 4,
        type_en: 'Finish material',
        product_zh: '马赛克',
        typeName: 'd5',
        product_en: 'Marble mosaics',
        top: '2352px',
        left: '1025px',
    },
    {
        id: 28,
        name: 'CONTARDI LIGHTING SRL',
        web: 'contardi-italia.it/',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via A. Manzoni, 134/140 20811 Cesano Maderno (MB)',
        email: 'sales@contardi-italia.it',
        tel: '0039 0362 301381',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '2267px',
        left: '943px',
    },
    {
        id: 29,
        name: 'RUGIANO INTERIORS DESIGN',
        web: 'www.rugiano.com/en',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via per Cascina Amata, 922066 - Mariano Comense (CO)',
        email: 'rugiano@rugiano.it',
        tel: '0039 031 752378',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2308px',
        left: '969px',
    },
    {
        id: 30,
        name: 'ALCANTARA SPA',
        web: 'www.alcantara.com',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via Mecenate, 86 - 20138 (MI)',
        email: 'andrea.stasi@alcantara.com',
        tel: '0039 02 580301',
        type: 4,
        type_en: 'Finish material',
        product_zh: '材料',
        typeName: 'd6',
        product_en: 'Materials',
        top: '2386px',
        left: '1058px',
    },
    {
        id: 31,
        name: 'MANIFATTURA DOMODOSSOLA',
        web: 'www.manifatturadomodossola.it',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Viale dell’Industria 1/3/5 28844 Villadossola (VB)',
        email: 'info@manifatturadomodossola.it',
        tel: '0039 0324225000',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '皮具',
        typeName: 'a5',
        product_en: 'Leatherware',
        top: '2053px',
        left: '579px',
    },
    {
        id: 32,
        name: 'B. LAB ITALIA SRL - TECKELL',
        web: 'www.teckell.com',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via Marmolada 20 - 21013 Gallarate (VA)',
        email: 'experience@teckell.com',
        tel: '0039 0331774445',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '创意家具',
        typeName: 'c8',
        product_en: 'Designed Products',
        top: '2197px',
        left: '779px',
    },
    {
        id: 33,
        name: 'INSPIREDRING SRL ',
        web: 'avellenttan.com/',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: "Via G.B. Pergolesi 22, 20124 Milano (MI)/Via Airone, 21 Porto D'Ascoli (AP) - Italy",
        email: 'info@avellenttan.com',
        tel: '0039 0280011021',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '2284px',
        left: '897px',
    },
    {
        id: 34,
        name: 'MEPRA SPA',
        web: 'www.mepra.it/en/',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'via Montini, 176, 25067 Lumezzane (BS)',
        email: 'mepra@mepra.it',
        tel: '0039 030 8921441',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '餐具',
        typeName: 'c9',
        product_en: 'Tablewear',
        top: '2190px',
        left: '1165px',
    },
    {
        id: 35,
        name: 'R.E.S. SRL',
        web: 'www.resitalia.it',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via Cassina Savina 49, 20831 Seregno (MB)',
        email: 'info@resitalia.it',
        tel: '0039 0362 238620',
        type: 4,
        type_en: 'Finish material',
        product_zh: '墙板',
        typeName: 'd7',
        product_en: 'Wall Paneling',
        top: '2296px',
        left: '1044px',
    },
    {
        id: 36,
        name: 'SOLEA SRL（MAJO)',
        web: 'www.solea.srl',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via Giuseppe Luosi, 10, 20131 Milano (MI)',
        email: 'info@solea.srl',
        tel: '0039 02 701 22762',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '包',
        typeName: 'a6',
        product_en: 'Bags',
        top: '2360px',
        left: '1024px',
    },
    {
        id: 37,
        name: 'VIBRAM SPA',
        web: 'www.vibram.com',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'VIA CRISTOFORO COLOMBO, 5, 21041 ALBIZZATE (VA)',
        email: 'info@vibramfivefingers.it',
        tel: '0039 0331 999 777',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '鞋',
        typeName: 'a2',
        product_en: 'Footwear',
        top: '2245px',
        left: '913px',
    },
    {
        id: 38,
        name: 'VIEFFE NOSELAB SRL',
        web: 'www.coquilleteparfum.com/',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via Corrado Alvaro n. 10,Milano - Italia',
        email: 'info@coquilletteparfum.com',
        tel: '0039 0287186616',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '香水',
        typeName: 'a7',
        product_en: 'Perfume',
        top: '2323px',
        left: '918px',
    },
    {
        id: 39,
        name: 'DI BI GROUP',
        web: 'www.dibigroup.com/en/',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'Via Einaudi 2 Z.I. 61032 FANO (PU) Italy',
        email: 'info@dibigroup.com',
        tel: '0039 0721 819 1',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '防盗门、墙板、窗户',
        typeName: 'c10',
        product_en: 'Security doors, panels and windows',
        top: '2983px',
        left: '1865px',
    },
    {
        id: 40,
        name: "BRUNEL SRL PREZIOSI D'AUTORE",
        web: 'brunel.it/',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: '62018 Potenza Picena (MC), Italy',
        email: 'info@brunel.it',
        tel: '0039 0733 884011',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '饰品、摆件',
        typeName: 'c11',
        product_en: 'Art Craftsmanship',
        top: '3177px',
        left: '1992px',
    },
    {
        id: 41,
        name: 'IGUZZINI ILLUMINAZIONE SPA',
        web: 'www.iguzzini.com/cn/',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'VIA MARIANO GUZZINI 37, 62019 RECANATI (MC)',
        email: 'luca.tarsetti@iguzzini.cn',
        tel: '0039 071 75881',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '3150px',
        left: '1905px',
    },
    {
        id: 42,
        name: 'BODYFLY SYSTEM SRLS',
        web: 'www.bodyfly.com',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'Via Gattoni 1/2, 61121 Pesaro (PU)',
        email: 'info@bodyfly.com',
        tel: '0039 339 545 1366',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '健身板',
        typeName: 'a8',
        product_en: 'Healthcare',
        top: '3052px',
        left: '1920px',
    },
    {
        id: 43,
        name: 'PIERMARIA NEW FORM SRL',
        web: 'www.piermariadesign.it',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'via Apsa 24/26, 61025 Montelabbate (PU)',
        email: 'info@piermarianewform.it',
        tel: '0039 0721 1391027',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '3005px',
        left: '1850px',
    },
    {
        id: 44,
        name: 'SCAVOLINI',
        web: 'www.scavolini.com',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'Via Risara, 60/70 - 74/78, 61025 Montelabbate (PU)',
        email: 'contatti@scavolini.com',
        tel: '0039 0721 443333',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '橱柜',
        typeName: 'b3',
        product_en: 'Kitchen Furniture',
        top: '3047px',
        left: '1970px',
    },
    {
        id: 45,
        name: 'OFIR GIOIELLI',
        web: 'www.ofirgioielli.com',
        area_zh: '皮埃蒙特',
        area_en: 'Piemonte',
        address: 'Corso Giacomo Matteotti, 59, 15048 Valenza AL, Italy',
        email: 'welcome@ofirgioielli.com',
        tel: '0039 0131 943200',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '2522px',
        left: '763px',
    },
    {
        id: 46,
        name: 'MARCHISIO 1959',
        web: 'www.marchisio1859.com',
        area_zh: '皮埃蒙特',
        area_en: 'Piemonte',
        address: 'Strada Torino n.79 10024,Moncalieri (TO)',
        email: 'alex@marchisio1859.com',
        tel: '0039 0116610417',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '2492px',
        left: '449px',
    },
    {
        id: 47,
        name: 'DESMOTEC SRL',
        web: 'www.desmotec.com',
        area_zh: '皮埃蒙特',
        area_en: 'Piemonte',
        address: 'VIA PIETRO BORA 10, 13900 Biella (BI)',
        email: 'info@desmotec.com',
        tel: '0039 015 582 2096',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '健身器材',
        typeName: 'c12',
        product_en: 'Gym equipment',
        top: '2295px',
        left: '495px',
    },
    {
        id: 48,
        name: 'DOMUS SRL',
        web: 'en.domus-homecollection.com/',
        area_zh: '普利亚',
        area_en: 'Puglia',
        address: 'Viale del Mattino, 3473046 Matino (LE)',
        email: 'info@domus-homecollection.com',
        tel: '0039 371 431 9774',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '床品',
        typeName: 'c4',
        product_en: 'Bedding',
        top: '4472px',
        left: '3460px',
    },
    {
        id: 49,
        name: 'LE DELLI SANTI SRLS',
        web: 'www.ledellisanti.com/en/',
        area_zh: '普利亚',
        area_en: 'Puglia',
        address: 'Via G. Nocera, 5 – 74016 Massafra (TA)',
        email: 'info@ledellisanti.com',
        tel: '0039 3517970232',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '4257px',
        left: '3084px',
    },
    {
        id: 50,
        name: 'NEROSICILIA  GROUP',
        web: 'nerosicilia.group/',
        area_zh: '西西里',
        area_en: 'Sicilia',
        address: 'Strada Provinciale Comiso-Chiaramonte km 6, 97012 Chiaramonte Gulfi (RG)',
        email: 'info@nerosicilia.com',
        tel: '0039 0932963254',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '洁具',
        typeName: 'c5',
        product_en: 'Sanitary Appliances',
        top: '5723px',
        left: '2372px',
    },
    {
        id: 51,
        name: 'OROGRAFIE SRL',
        web: 'www.orografie.com',
        area_zh: '西西里',
        area_en: 'Sicilia',
        address: 'VIA MILANO, 40, 95127 Catania (CT)',
        email: 'info@orografie.com',
        tel: '0039 380 7788060',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '5534px',
        left: '2508px',
    },
    {
        id: 52,
        name: 'FORMITALIA GROUP SPA',
        web: 'www.formitalia.it/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'via Corticella 5/7/9, 51039 Valenzatico (PT)',
        email: 'info@formitalia.it',
        tel: '0039 0573 790066',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '3006px',
        left: '1314px',
    },
    {
        id: 53,
        name: 'SAVIO FIRMINO',
        web: 'www.saviofirmino.com/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Delle Fonti, 10 50018 Scandicci - (FI)',
        email: 'info@saviofirmino.com',
        tel: '0039 055 720466',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2940px',
        left: '1242px',
    },
    {
        id: 54,
        name: 'BUSATTI SRL',
        web: 'shop.busatti.com/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Mazzini, 14 52031 Anghiari (AR)',
        email: 'shop@busatti.com',
        tel: '0039 0575 788013',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '家居用品',
        typeName: 'c13',
        product_en: 'Furnishing',
        top: '3051px',
        left: '1640px',
    },
    {
        id: 55,
        name: 'BEDIN MARMI',
        web: 'www.bedinimarmi.com/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Viale Galileo Galilei 48P 54033  Marina di Carrara (MS)',
        email: 'info@bedinimarmi.com',
        tel: '0039 0585 857895',
        type: 4,
        type_en: 'Finish material',
        product_zh: '石材及相关产品',
        typeName: 'd8',
        product_en: 'marble, stones and granites',
        top: '2380px',
        left: '1503px',
    },
    {
        id: 56,
        name: 'PERONI FIRENZE',
        web: 'peronifirenze.com/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Guglielmo Marconi 82r int, 50131 Firenze (FI)',
        email: 'info@peronifirenze.it',
        tel: '0039 055572520',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '皮具',
        typeName: 'a5',
        product_en: 'Leather products',
        top: '3033px',
        left: '1365px',
    },
    {
        id: 57,
        name: 'AGRESTI',
        web: 'www.agresti.com/en',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Don Lorenzo Perosi n°1 - 50018 Scandicci (FI)',
        email: 'agresti@agresti.com',
        tel: '0039 055 750928',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '保险柜',
        typeName: 'b5',
        product_en: 'Safe box',
        top: '3039px',
        left: '1316px',
    },
    {
        id: 58,
        name: 'TARGETTI',
        web: 'www.targetti.com',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Pratese, 164 50145 Firenze (FI)',
        email: 'targetti@targetti.com',
        tel: '0039  055 37911',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '2993px',
        left: '1410px',
    },
    {
        id: 59,
        name: 'FRATELLI BORGIOLI SRL',
        web: 'www.fratelliborgioli.com',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Maremmana, 17150059 Vinci (FI)',
        email: 'hello@fratelliborgioli.com',
        tel: '0039 571585881',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '鞋',
        typeName: 'a2',
        product_en: 'Footwear',
        top: '2950px',
        left: '1218px',
    },
    {
        id: 60,
        name: 'ALBERO BAMBINO',
        web: 'www.alberobambino.it/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Provinciale, 83 50038 Scarperia e San Piero (FI)',
        email: 'info@alberobambino.it',
        tel: '0039 0552694819',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2902px',
        left: '1349px',
    },
    {
        id: 61,
        name: 'Maska Srl',
        web: 'www.maska.it/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via A. Gramsci n 21, 50022 Greve in Chianti (Fi)',
        email: 'info@maska.it',
        tel: '0039  3207551400',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '服饰',
        typeName: 'a3',
        product_en: 'Clothing',
        top: '3070px',
        left: '1425px',
    },
    {
        id: 62,
        name: 'MARIONI SRL',
        web: 'www.marioni.it',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'VIA G.GIUSTI 199, 50041 Calenzano (FI)',
        email: 'marioni@marioni.it',
        tel: '0039 055 887 9346',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具、饰品',
        typeName: 'b6',
        product_en: 'Furniture,Ornaments',
        top: '2968px',
        left: '1402px',
    },
    {
        id: 63,
        name: 'CORIUM ITALIA SRL',
        web: 'www.coriumitalia.com/home.php',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'VIA Montalbano,169,51039 Quarrata,PistoiaI,Italia',
        email: 'coriumitalia@coriumitalia.com',
        tel: '0039 0573.1915711',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2868px',
        left: '1194px',
    },
    {
        id: 64,
        name: 'MARIGROUP & CO SRL',
        web: 'kontessa.it/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Regno Unito, n. 23 zona ind. La Tura fraz. Cascine di Buti56032 Buti (Pisa) Italy',
        email: 'press@kontessa.it',
        tel: '0039 0587723484',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '服饰',
        typeName: 'a3',
        product_en: 'Clothing',
        top: '2901px',
        left: '1098px',
    },
    {
        id: 65,
        name: 'OROGAMI DI TIZIANA ORCIUOLO',
        web: 'www.orogami.com',
        area_zh: '翁布里亚',
        area_en: 'Umbria',
        address: 'Via del Duomo 14/16, 05018, Orvieto (TR)',
        email: 'orogami@orogami.com',
        tel: '0039 0763 344206',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '3456px',
        left: '1616px',
    },
    {
        id: 66,
        name: 'PRODITAL ITALIA',
        web: 'www.prodital.it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via L. Da Vinci 34 - Arzignano (VI)',
        email: 'info@prodital.it',
        tel: '0039 0444 482354',
        type: 4,
        type_en: 'Finish material',
        product_zh: '皮料',
        typeName: 'd9',
        product_en: 'Leather',
        top: '2073px',
        left: '1608px',
    },
    {
        id: 67,
        name: 'SARTOR GIULIANO',
        web: 'www.sartor1956.com/',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Fenadora 49 - 32030 Fonzaso (BL) ITALIA',
        email: 'info@sartor1956.com',
        tel: '0039 0439 1998412',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '1980px',
        left: '1575px',
    },
    {
        id: 68,
        name: 'POINT SRL- brand Pointhouse Creative',
        web: 'www.pointhouse.it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'via Veneto, 10 - 31040 Gorgo al Monticano (TV)',
        email: 'i.greguol@pointhouse.it',
        tel: '0039 0422 800019',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2241px',
        left: '1669px',
    },
    {
        id: 69,
        name: 'PRANE DESIGN',
        web: 'www.pranedesign.com/',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Col Moschin, 3, 35018 San Martino di Lupari (PD)',
        email: 'info@pranedesign.com',
        tel: '0039 049 9470706',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '沙发',
        typeName: 'b7',
        product_en: 'Sofa',
        top: '2305px',
        left: '1548px',
    },
    {
        id: 70,
        name: 'GSC LIGHTING',
        web: 'www.gsclight.com',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Viale del Lavoro 30 35010-Vigonza(PD)',
        email: 'graziano.costantino@gsclight.com',
        tel: '0039 0492328947',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '2327px',
        left: '1561px',
    },
    {
        id: 71,
        name: 'ANNIE CLAIRE Quality Furniture srl',
        web: 'www.annieclaire.it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'via Roma 113, 36060 Schiavon (VI)',
        email: 'info@annieclaire.eu',
        tel: '0039 0444 466939',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2271px',
        left: '1436px',
    },
    {
        id: 72,
        name: 'DANI SPA',
        web: 'www.gruppodani.com/',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Della Concia, 186, 36071 – Arzignano (VI)',
        email: 'info@gruppodani.it',
        tel: '0039 0444 454111',
        type: 4,
        type_en: 'Finish material',
        product_zh: '皮料',
        typeName: 'd9',
        product_en: 'Leather',
        top: '2317px',
        left: '1459px',
    },
    {
        id: 73,
        name: 'ALBERTA PACIFIC FURNITURE',
        web: 'www.alberta.it/',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Toniolo, 41, 31028 Vazzola (TV)',
        email: 'contact@albertasalotti.it',
        tel: '0039 0438440440',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2138px',
        left: '1584px',
    },
    {
        id: 74,
        name: 'BAROVIER & TOSO - Vetrerie artistiche riunite Srl',
        web: 'www.barovier.com/it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Fondamenta Vetrai, 28, 30141 Murano (VE)',
        email: 'info@barovier.com',
        tel: '0039 041 739 049',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '2292px',
        left: '1600px',
    },
    {
        id: 75,
        name: 'FONTANA PIETRO S.P.A.(AltreForme)',
        web: 'www.fontana-group.com',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'VIALE ALCIDE DE GASPERI, 16, 23801 Brendola (VI)',
        email: 'info@fontana-group.com',
        tel: '0039 0341 6381',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2270px',
        left: '907px',
    },
    {
        id: 76,
        name: 'GRASSI PIETRE SRL',
        web: 'www.grassipietre.it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Madonetta, 2, 36024 Nanto (VI) - Italy',
        email: 'info@grassipietre.it',
        tel: '0039 0444 639092',
        type: 4,
        type_en: 'Finish material',
        product_zh: '石材制品',
        typeName: 'd10',
        product_en: 'Stone products',
        top: '2350px',
        left: '1334px',
    },
    {
        id: 77,
        name: 'MAGIS SPA',
        web: 'www.magisdesign.com',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'via Triestina -accesso E - zona Pontetezze, 30020 Torre di Mosto (VE)',
        email: 'info@magisdesign.com',
        tel: '0039 0421319600',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2208px',
        left: '1790px',
    },
    {
        id: 78,
        name: 'Memmo-Venezia S.r.l.',
        web: 'www.memmo-venezia.com',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Campo San Pantalon 3700, 30123 Venezia (VE)',
        email: 'info@memmo-venezia.com',
        tel: '0039 351 5660141',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '水晶玻璃制品',
        typeName: 'c14',
        product_en: 'Glass products',
        top: '2334px',
        left: '1796px',
    },
    {
        id: 79,
        name: 'TAROCCO VACCARI GROUP SRL',
        web: 'www.taroccovaccarigroup.it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Cappello 14 - 37051 Bovolone (VR)',
        email: 'info@taroccovaccarigroup.it',
        tel: '0039 045 7101225',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2664px',
        left: '1370px',
    },
    {
        id: 80,
        name: '	THE HIVE S.R.L.',
        web: 'www.thehiveitalia.com',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: "Viale Del'Industria 19,35129 (PD)",
        email: 'thehiveitalia@gmail.com',
        tel: '0039 327 7697101',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '服饰',
        typeName: 'a3',
        product_en: 'Clothing',
        top: '2431px',
        left: '1496px',
    },
    {
        id: 81,
        name: 'IDL EXPORT SRL',
        web: 'www.idl1987.it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via S. Marco, 14, 31050 Fanzolo TV, Italy',
        email: 'info@idlexport.it',
        tel: '0039 0423487141',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '2960px',
        left: '1424px',
    },
    {
        id: 82,
        name: 'Modenese Gastone Interiors s.r.l.',
        web: 'modeneseinteriors.com/',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Roma st, 92/106, 35040, Casale di Scodosia,Padova, Italy',
        email: 'info@modeneseinteriors.com',
        tel: '0039 0429 879146',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2399px',
        left: '1389px',
    },
    {
        id: 83,
        name: 'CAFEDESART',
        web: 'cafedesart.com/en/',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Muri 44, 37050 Bonavicina San Pietro di Morubio, VR (Italy)',
        email: 'sales@cafedesart.com',
        tel: '0039 045 712 5095',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2433px',
        left: '1427px',
    },
    {
        id: 84,
        name: 'LEONORI',
        web: 'www.leonorigioielli.com',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'C.so Vittorio Emanuele,2 63100 Ascoli Piceno(AP) Italy',
        email: 'info@leonorigioielli.com',
        tel: '0039 342 5772788',
        type: 1,
        type_en: 'Fashion  product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '3320px',
        left: '2076px',
    },
];
// 产品分类数据
export const productType = [
    {
        text: '分类',
        value: 0, // 不筛选
    },
    {
        text: '时尚产品',
        value: 1, // type值
    },
    {
        text: '家具产品',
        value: 2, // type值
    },
    {
        text: '家居产品',
        value: 3, // type值
    },
    {
        text: '饰面材料',
        value: 4, // type值
    },
];
// 时尚产品 具体产品名字数据
export const productName1 = [
    {
        text: '产品',
        value: '0', // 不筛选
    },
    {
        text: '首饰',
        value: 'a1', // type值
    },
    {
        text: '鞋',
        value: 'a2', // type值
    },
    {
        text: '服饰',
        value: 'a3', // type值
    },
    {
        text: '眼镜',
        value: 'a4', // type值
    },
    {
        text: '皮具',
        value: 'a5', // type值
    },
    {
        text: '包',
        value: 'a6', // type值
    },
    {
        text: '香水',
        value: 'a7', // type值
    },
    {
        text: '健身板',
        value: 'a8', // type值
    },
];
// 家具产品 具体产品名字数据
export const productName2 = [
    {
        text: '产品',
        value: '0', // 不筛选
    },
    {
        text: '防盗门',
        value: 'b1', // type值
    },
    {
        text: '家具',
        value: 'b2', // type值
    },
    {
        text: '橱柜',
        value: 'b3', // type值
    },
    {
        text: '家具、板材、木门',
        value: 'b4', // type值
    },
    {
        text: '保险柜',
        value: 'b5', // type值
    },
    {
        text: '家具、饰品',
        value: 'b6', // type值
    },
    {
        text: '沙发',
        value: 'b7', // type值
    },
];
// 家居产品 具体产品名字数据
export const productName3 = [
    {
        text: '产品',
        value: '0', // 不筛选
    },
    {
        text: '床垫',
        value: 'c1', // type值
    },
    {
        text: '灯具',
        value: 'c2', // type值
    },
    {
        text: '家用电器',
        value: 'c3', // type值
    },
    {
        text: '床品',
        value: 'c4', // type值
    },
    {
        text: '洁具',
        value: 'c5', // type值
    },
    {
        text: '窗帘',
        value: 'c6', // type值
    },
    {
        text: '开关系统',
        value: 'c7', // type值
    },
    {
        text: '创意家具',
        value: 'c8', // type值
    },
    {
        text: '餐具',
        value: 'c9', // type值
    },
    {
        text: '防盗门、墙板、窗户',
        value: 'c10', // type值
    },
    {
        text: '饰品、摆件',
        value: 'c11', // type值
    },
    {
        text: '健身器材',
        value: 'c12', // type值
    },
    {
        text: '家居用品',
        value: 'c13', // type值
    },
    {
        text: '水晶玻璃制品',
        value: 'c14', // type值
    },
];
// 饰面材料 具体产品名字数据
export const productName4 = [
    {
        text: '产品',
        value: '0', // 不筛选
    },
    {
        text: '大理石',
        value: 'd1', // type值
    },
    {
        text: '石材',
        value: 'd2', // type值
    },
    {
        text: '瓷砖',
        value: 'd3', // type值
    },
    {
        text: '环保板材',
        value: 'd4', // type值
    },
    {
        text: '马赛克',
        value: 'd5', // type值
    },
    {
        text: '材料',
        value: 'd6', // type值
    },
    {
        text: '墙板',
        value: 'd7', // type值
    },
    {
        text: '石材及相关产品',
        value: 'd8', // type值
    },
    {
        text: '皮料',
        value: 'd9', // type值
    },
    {
        text: '石材制品',
        value: 'd10', // type值
    },
];
// 产品分布区域数据
export const areaData = [
    { id: 1, num: 12, top: '1940px', left: '750px' },
    { id: 2, num: 20, top: '1930px', left: '1540px' },
    { id: 3, num: 12, top: '2372px', left: '1102px' },
    { id: 4, num: 20, top: '2750px', left: '1830px' },
    { id: 5, num: 13, top: '3602px', left: '2031px' },
    { id: 6, num: 2, top: '3930px', left: '3255px' },
    { id: 7, num: 4, top: '5030px', left: '2460px' },
];
// 产品分类icon及文字对应颜色数据
export const colorData = ['#d90427', '#18448f', '#eaa700', '#00841b'];
// 埋点数据-产品分类
export const amberTypeData = {
    0: '分类',
    1: '时尚产品',
    2: '家具产品',
    3: '家居产品',
    4: '饰面材料',
};
// 埋点数据-具体产品名字
export const amberNameData = {
    0: '产品',
    a1: '首饰',
    a2: '鞋',
    a3: '服饰',
    a4: '眼镜',
    a5: '皮具',
    a6: '包',
    a7: '香水',
    a8: '健身板',
    b1: '防盗门',
    b2: '家具',
    b3: '橱柜',
    b4: '家具、板材/木门',
    b5: '保险柜',
    b6: '家具、饰品',
    b7: '沙发',
    c1: '床垫',
    c2: '灯具',
    c3: '家用电器',
    c4: '床品',
    c5: '洁具',
    c6: '窗帘',
    c7: '开关系统',
    c8: '创意家具',
    c9: '餐具',
    c10: '防盗门、墙板、窗户',
    c11: '饰品、摆件',
    c12: '健身器材',
    c13: '家居用品',
    c14: '水晶玻璃制品',
    d1: '大理石',
    d2: '石材',
    d3: '瓷砖',
    d4: '环保板材',
    d5: '马赛克',
    d6: '材料',
    d7: '墙板',
    d8: '石材及相关产品',
    d9: '皮料',
    d10: '石材制品',
};
// 路线数据
export const roadLineData = [
    {
        id: 17,
        name: 'FLORIM CERAMICHE SPA',
        web: 'www.florim.com/it/',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: ' Via Canaletto 24, 41042 Fiorano Modenese',
        email: 'expo.expo@florim.com',
        tel: '0039 054257111',
        type: 4,
        type_en: 'Finish material ------------------',
        product_zh: '瓷砖',
        typeName: 'd3',
        product_en: 'Tile',
        top: '2791px',
        left: '1426px',
    },
    {
        id: 18,
        name: 'TONDELLI SRL',
        web: 'www.lorenzotondelli.com',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: 'Via B. Franklin 5/A 41012, Carpi (MO) ',
        email: 'info@tondelliarredamenti.com',
        tel: '0039 059643928',
        type: 2,
        type_en: 'Furniture  product--',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2670px',
        left: '1340px',
    },
    {
        id: 29,
        name: 'RUGIANO INTERIORS DESIGN',
        web: 'www.rugiano.com/en',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via per Cascina Amata, 922066 - Mariano Comense (CO)',
        email: 'rugiano@rugiano.it',
        tel: '0039 031 752378',
        type: 2,
        type_en: 'Furniture  product---------------',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2230px',
        left: '734px',
    },
    {
        id: 41,
        name: 'IGUZZINI ILLUMINAZIONE SPA',
        web: 'www.iguzzini.com/cn/',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'VIA MARIANO GUZZINI 37, 62019 RECANATI (MC)',
        email: 'luca.tarsetti@iguzzini.cn',
        tel: '0039 071 75881',
        type: 3,
        type_en: 'Furnishing--------------------------',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '3162px',
        left: '1804px',
    },
    {
        id: 44,
        name: 'SCAVOLINI',
        web: 'www.scavolini.com',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'Via Risara, 60/70 - 74/78, 61025 Montelabbate (PU)',
        email: 'contatti@scavolini.com',
        tel: '0039 0721 443333',
        type: 2,
        type_en: 'Furniturepr---',
        product_zh: '橱柜',
        typeName: 'b3',
        product_en: 'Kitchen Furniture',
        top: '3084px',
        left: '1780px',
    },
    {
        id: 52,
        name: 'FORMITALIA GROUP SPA',
        web: 'www.formitalia.it/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'via Corticella 5/7/9, 51039 Valenzatico (PT)',
        email: 'info@formitalia.it',
        tel: '0039 0573 790066',
        type: 2,
        type_en: 'Furniture  product-----------',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2969px',
        left: '976px',
    },
    {
        id: 58,
        name: 'TARGETTI',
        web: 'www.targetti.com',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Pratese, 164 50145 Firenze (FI)',
        email: 'targetti@targetti.com',
        tel: '0039  055 37911',
        type: 3,
        type_en: 'Furnishing---',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '3118px',
        left: '1398px',
    },
    {
        id: 72,
        name: 'DANI SPA',
        web: 'www.gruppodani.com/',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Della Concia, 186, 36071 – Arzignano (VI)',
        email: 'info@gruppodani.it',
        tel: '0039 0444 454111',
        type: 4,
        type_en: 'Finish mate--',
        product_zh: '皮料',
        typeName: 'd9',
        product_en: 'Leather',
        top: '2066px',
        left: '1350px',
    },
    {
        id: 74,
        name: 'BAROVIER & TOSO - Vetrerie artistiche riunite Srl',
        web: 'www.barovier.com/it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Fondamenta Vetrai, 28, 30141 Murano (VE)',
        email: 'info@barovier.com',
        tel: '0039 041 739 049',
        type: 3,
        type_en: 'Furnishing-------------------------------------------',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '2276px',
        left: '1370px',
    },
    {
        id: 77,
        name: 'MAGIS SPA',
        web: 'www.magisdesign.com',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'via Triestina -accesso E - zona Pontetezze, 30020 Torre di Mosto (VE)',
        email: 'info@magisdesign.com',
        tel: '0039 0421319600',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2158px',
        left: '1583px',
    },
];
// icon图标数据
export const iconData = [
    {
        id: 1,
        type: 2,
        top: '1855px',
        left: '1613px',
    },
    {
        id: 2,
        type: 3,
        top: '2200px',
        left: '670px',
    },
    {
        id: 3,
        type: 4,
        top: '2200px',
        left: '1270px',
    },
    {
        id: 4,
        type: 2,
        top: '2350px',
        left: '1200px',
    },
    {
        id: 5,
        type: 1,
        top: '2450px',
        left: '550px',
    },
    {
        id: 6,
        type: 2,
        top: '2420px',
        left: '1460px',
    },
    {
        id: 7,
        type: 4,
        top: '2640px',
        left: '1080px',
    },
    {
        id: 8,
        type: 3,
        top: '2690px',
        left: '1280px',
    },
    {
        id: 9,
        type: 3,
        top: '2830px',
        left: '2010px',
    },
    {
        id: 10,
        type: 2,
        top: '3240px',
        left: '1380px',
    },
    {
        id: 11,
        type: 1,
        top: '3340px',
        left: '1800px',
    },
    {
        id: 12,
        type: 3,
        top: '3690px',
        left: '1950px',
    },
    {
        id: 13,
        type: 4,
        top: '3820px',
        left: '2550px',
    },
    {
        id: 14,
        type: 2,
        top: '3820px',
        left: '3090px',
    },
    {
        id: 15,
        type: 2,
        top: '5100px',
        left: '2220px',
    },
];
// 全部产品的数据
export const productPointData = [
    {
        id: 1,
        name: 'METALSUD LO GATTO SRL',
        web: 'www.ferwall.it/',
        area_zh: '卡拉布里亚',
        area_en: 'Calabria',
        address: 'Zona Industriale Località Aeroporto, 8990 Vibo Valentia (VV)',
        email: 'info@ferwall.it',
        tel: '0039 0963 265872',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '防盗门',
        typeName: 'b1',
        product_en: 'Security doors',
        top: '5054px',
        left: '2785px',
    },
    {
        id: 2,
        name: 'OFFICINA ORAFA',
        web: 'www.officina-orafa.com',
        area_zh: '卡拉布里亚',
        area_en: 'Calabria',
        address: 'Viale J. F. Kennedy, 12 – 88062 Cardinale (CZ)',
        email: 'info@officina-orafa.com',
        tel: '0039 0967430172',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '5028px',
        left: '2960px',
    },
    {
        id: 3,
        name: "D'ELIA DI VITIELLO  ALFONSO",
        web: 'www.deliacompany.it/',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Via E. De Nicola, 15 80059 Torre del Greco (NA)',
        email: 'info@deliacompany.it',
        tel: '0039 081 881 1797',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '4065px',
        left: '2105px',
    },
    {
        id: 4,
        name: 'CIANCIULLO MARMI SRL',
        web: 'www.cianciullo.it',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Via Isaia Rossi 49-5184014 Nocera Inferiore (SA) Italiy',
        email: 'info@cianciullo.it',
        tel: '0039 089301308',
        type: 4,
        type_en: 'Finish material',
        product_zh: '大理石',
        typeName: 'd1',
        product_en: 'Marble',
        top: '4118px',
        left: '2223px',
    },
    {
        id: 5,
        name: 'Calpierre S.R.L.',
        web: 'www.calpierre.it',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Viale Olimpico, 5, 80025 Casandrino NA, Italy',
        email: 'fairs@calpierre.it',
        tel: '0039 081 5055589',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '鞋',
        typeName: 'a2',
        product_en: 'Footwear',
        top: '4118px',
        left: '2320px',
    },
    {
        id: 6,
        name: 'FGM MARMI ',
        web: 'www.marmibizzarro.it/',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Via Lampione, 27 82032 Cerreto Sannita (BN)',
        email: 'info@marmibizzarro.it',
        tel: '0039 0824861583',
        type: 4,
        type_en: 'Finish material',
        product_zh: '大理石',
        typeName: 'd1',
        product_en: 'Marble',
        top: '2692px',
        left: '1328px',
    },
    {
        id: 7,
        name: 'RINALDI GROUP SRL',
        web: 'www.rinaldigroup.com',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Via S. Maria zona P.I.P, 84095 Giffoni Valle Piana SA',
        email: 'info@rinaldigroup.com',
        tel: '0039 089.868706',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '床垫',
        typeName: 'c1',
        product_en: 'Mattresses',
        top: '4102px',
        left: '2371px',
    },
    {
        id: 8,
        name: 'ROSMUNDO DI ROSMUNDO GIARLETTA',
        web: 'www.rosmundo.com',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Corso Giuseppe Garibaldi 90 84025 Eboli (SA)',
        email: 'info@rosmundo.com',
        tel: '0039 3334543263',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '4165px',
        left: '2319px',
    },
    {
        id: 9,
        name: 'RANIERI PIETRA LAVICA ',
        web: 'www.ranierilavastone.com',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Via Vicinale Lava Ranieri snc, 80040 Terzigno (NA)',
        email: 'info@ranierilavastone.com',
        tel: '0039 0815292962',
        type: 4,
        type_en: 'Finish material',
        product_zh: '石材',
        typeName: 'd2',
        product_en: 'Stone products',
        top: '4148px',
        left: '2202px',
    },
    {
        id: 10,
        name: 'CHIARAD. SRL',
        web: 'www.chiaradeconcilio.style',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'VIA ANIELLO FALCONE 32, 80127 Napoli (NA)',
        email: 'info@chiaradeconcilio.style',
        tel: '0039 392 353 8461',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '服饰',
        typeName: 'a3',
        product_en: 'Clothing',
        top: '4022px',
        left: '2141px',
    },
    {
        id: 11,
        name: 'EMME.C S.R.L.',
        web: 'www.russocammei.it',
        area_zh: '坎帕尼亚',
        area_en: 'Campania',
        address: 'Centro orafo Oromare Strada Provinciale,22KM 1750 81025 Marcianise(CE)',
        email: 'info@russocammei.it',
        tel: '0039 082301644640',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '4084px',
        left: '2430px',
    },
    {
        id: 12,
        name: 'GRANITI FIANDRE SPA',
        web: 'www.irisceramicagroup.com/',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: 'Via Radici Nord, 112 - 42014 Castellarano (RE) ',
        email: 'wwang@iris-group.it',
        tel: '0039 0536 862111',
        type: 4,
        type_en: 'Finish material',
        product_zh: '瓷砖',
        typeName: 'd3',
        product_en: 'Ceramics',
        top: '2735px',
        left: '1250px',
    },
    {
        id: 13,
        name: 'VIA BIZZUNO SRL',
        web: 'www.viabizzuno.com/en/',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: 'Via Luciano Romagnoli, 10, 40010 Bentivoglio (BO)',
        email: 'l.chinello@viabizzuno.com',
        tel: '0039 0541 787948',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '2709px',
        left: '1492px',
    },
    {
        id: 14,
        name: 'SMEG',
        web: 'www.smeg.it/',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: 'Via Leonardo da Vinci, 4 42016 Guastalla (RE)',
        email: 'smeg@smeg.it',
        tel: '0039 0522 8211',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '家用电器',
        typeName: 'c3',
        product_en: 'Household appliances',
        top: '2618px',
        left: '1369px',
    },
    {
        id: 15,
        name: 'SVADON DONDI SPA',
        web: 'www.dondi.it/it/',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: 'Via Dei Tigli, 2 46019 Viadana (MN) - Italy',
        email: 'web.admin@dondi.it',
        tel: '0039 0375 830121',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '床品',
        typeName: 'c4',
        product_en: 'Bedding',
        top: '2587px',
        left: '1168px',
    },
    {
        id: 16,
        name: 'MAKTRON ENTERPRICES SRL',
        web: 'italiansolutions.com/',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: 'Via Cangelasio Moracchi, 85 43039 Salsomaggiore Terme (PR)',
        email: 'info@italiansolutions.com',
        tel: '0039 0524 571550',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2569px',
        left: '991px',
    },
    {
        id: 17,
        name: 'FLORIM CERAMICHE SPA',
        web: 'www.florim.com/it/',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: ' Via Canaletto 24, 41042 Fiorano Modenese',
        email: 'expo.expo@florim.com',
        tel: '0039 054257111',
        type: 4,
        type_en: 'Finish material',
        product_zh: '瓷砖',
        typeName: 'd3',
        product_en: 'Tile',
        top: '2780px',
        left: '1507px',
    },
    {
        id: 18,
        name: 'TONDELLI SRL',
        web: 'www.lorenzotondelli.com',
        area_zh: '艾米利亚-罗马涅',
        area_en: 'Emilia Romagna',
        address: 'Via B. Franklin 5/A 41012, Carpi (MO) ',
        email: 'info@tondelliarredamenti.com',
        tel: '0039 059643928',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2627px',
        left: '1427px',
    },
    {
        id: 19,
        name: 'GEES RECYCLING SRL',
        web: 'www.geesrecycling.com',
        area_zh: '弗留利-威尼斯朱利亚',
        area_en: 'Friuli Venezia Giulia',
        address: 'Via Monte Colombera n. 22 33081 Aviano (PN)',
        email: 'geesrecycling@gmail.com',
        tel: '0039 0434 654183',
        type: 4,
        type_en: 'Finish material',
        product_zh: '环保板材',
        typeName: 'd4',
        product_en: 'Panel',
        top: '2114px',
        left: '1659px',
    },
    {
        id: 20,
        name: 'SNAIDERO RINO',
        web: 'www.snaidero.it',
        area_zh: '弗留利-威尼斯朱利亚',
        area_en: 'Friuli Venezia Giulia',
        address: 'Viale Rino Snaidero Cavaliere del Lavoro, 15 33030 Majano Udine (Italy)',
        email: 'rdreolini@snaidero.it',
        tel: '0039 00153510300',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '橱柜',
        typeName: 'b3',
        product_en: 'Kitchen Furniture',
        top: '2090px',
        left: '1862px',
    },
    {
        id: 21,
        name: 'KERASAN',
        web: 'www.kerasan.it/',
        area_zh: '拉齐奥',
        area_en: 'Lazio',
        address: 'Via Albert Einstein, 301033 Civita Castellana (VT)',
        email: 'kerasan@kerasan.it',
        tel: '0039 0761 540627',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '洁具',
        typeName: 'c5',
        product_en: 'Sanitary Appliances',
        top: '3652px',
        left: '1678px',
    },
    {
        id: 22,
        name: 'TENDATECH',
        web: 'www.artendis.com/',
        area_zh: '拉齐奥',
        area_en: 'Lazio',
        address: 'Strada della Neve, 211, 00018 Palombara Sabina (RM)',
        email: 'LORIS@ARTENDIS.COM',
        tel: '0039 0774 630730',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '窗帘',
        typeName: 'c6',
        product_en: 'Curtains',
        top: '3681px',
        left: '1771px',
    },
    {
        id: 23,
        name: 'LOGOPROJECT DESIGN',
        web: 'www.logoproject.it/en/',
        area_zh: '拉齐奥',
        area_en: 'Lazio',
        address: 'Via Salaria, 267, 00199 Roma (RM)',
        email: 'b.tolomei@logoproject.it',
        tel: '0039 0695 479068',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '眼镜',
        typeName: 'a4',
        product_en: 'Eyewear',
        top: '3730px',
        left: '1746px',
    },
    {
        id: 24,
        name: 'SLAMP SPA',
        web: 'www.slamp.com',
        area_zh: '拉齐奥',
        area_en: 'Lazio',
        address: 'Via Vaccareccia, 14, 00071 Pomezia (RM)',
        email: 'info@slamp.it',
        tel: '0039 06 916 2391',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '3796px',
        left: '1717px',
    },
    {
        id: 25,
        name: 'FLOEMA SRL',
        web: 'www.floema.it',
        area_zh: '拉齐奥',
        area_en: 'Lazio',
        address: 'Via XX Settembre, 118 00184 ROMA, Italia',
        email: 'info@floema.it ',
        tel: '0039 06 622 89 727',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具、板材、木门',
        typeName: 'b4',
        product_en: 'Furniture,Panel,Door',
        top: '3046px',
        left: '1749px',
    },
    {
        id: 26,
        name: 'BITICINO SPA',
        web: 'www.bticino.com/',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Viale Luigi Borri, 231, 21100 Varese (VA)',
        email: 'export.bticino@bticino.com.',
        tel: '0039 0332 272111',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '开关系统',
        typeName: 'c7',
        product_en: 'Electric Systems',
        top: '2230px',
        left: '891px',
    },
    {
        id: 27,
        name: 'SICIS',
        web: 'www.sicis.com/',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via Borgonuovo, 29 – 20121 Milano (MI)',
        email: 'info@sicis.com',
        tel: '0039 0544 469711',
        type: 4,
        type_en: 'Finish material',
        product_zh: '马赛克',
        typeName: 'd5',
        product_en: 'Marble mosaics',
        top: '2352px',
        left: '1025px',
    },
    {
        id: 28,
        name: 'CONTARDI LIGHTING SRL',
        web: 'contardi-italia.it/',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via A. Manzoni, 134/140 20811 Cesano Maderno (MB)',
        email: 'sales@contardi-italia.it',
        tel: '0039 0362 301381',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '2267px',
        left: '943px',
    },
    {
        id: 29,
        name: 'RUGIANO INTERIORS DESIGN',
        web: 'www.rugiano.com/en',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via per Cascina Amata, 922066 - Mariano Comense (CO)',
        email: 'rugiano@rugiano.it',
        tel: '0039 031 752378',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2308px',
        left: '969px',
    },
    {
        id: 30,
        name: 'ALCANTARA SPA',
        web: 'www.alcantara.com',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via Mecenate, 86 - 20138 (MI)',
        email: 'andrea.stasi@alcantara.com',
        tel: '0039 02 580301',
        type: 4,
        type_en: 'Finish material',
        product_zh: '材料',
        typeName: 'd6',
        product_en: 'Materials',
        top: '2386px',
        left: '1058px',
    },
    {
        id: 31,
        name: 'MANIFATTURA DOMODOSSOLA',
        web: 'www.manifatturadomodossola.it',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Viale dell’Industria 1/3/5 28844 Villadossola (VB)',
        email: 'info@manifatturadomodossola.it',
        tel: '0039 0324225000',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '皮具',
        typeName: 'a5',
        product_en: 'Leatherware',
        top: '2053px',
        left: '579px',
    },
    {
        id: 32,
        name: 'B. LAB ITALIA SRL - TECKELL',
        web: 'www.teckell.com',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via Marmolada 20 - 21013 Gallarate (VA)',
        email: 'experience@teckell.com',
        tel: '0039 0331774445',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '创意家具',
        typeName: 'c8',
        product_en: 'Designed Products',
        top: '2197px',
        left: '779px',
    },
    {
        id: 33,
        name: 'INSPIREDRING SRL ',
        web: 'avellenttan.com/',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: "Via G.B. Pergolesi 22, 20124 Milano (MI)/Via Airone, 21 Porto D'Ascoli (AP) - Italy",
        email: 'info@avellenttan.com',
        tel: '0039 0280011021',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '2284px',
        left: '897px',
    },
    {
        id: 34,
        name: 'MEPRA SPA',
        web: 'www.mepra.it/en/',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'via Montini, 176, 25067 Lumezzane (BS)',
        email: 'mepra@mepra.it',
        tel: '0039 030 8921441',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '餐具',
        typeName: 'c9',
        product_en: 'Tablewear',
        top: '2190px',
        left: '1165px',
    },
    {
        id: 35,
        name: 'R.E.S. SRL',
        web: 'www.resitalia.it',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via Cassina Savina 49, 20831 Seregno (MB)',
        email: 'info@resitalia.it',
        tel: '0039 0362 238620',
        type: 4,
        type_en: 'Finish material',
        product_zh: '墙板',
        typeName: 'd7',
        product_en: 'Wall Paneling',
        top: '2296px',
        left: '1044px',
    },
    {
        id: 36,
        name: 'SOLEA SRL（MAJO)',
        web: 'www.solea.srl',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via Giuseppe Luosi, 10, 20131 Milano (MI)',
        email: 'info@solea.srl',
        tel: '0039 02 701 22762',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '包',
        typeName: 'a6',
        product_en: 'Bags',
        top: '2360px',
        left: '1024px',
    },
    {
        id: 37,
        name: 'VIBRAM SPA',
        web: 'www.vibram.com',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'VIA CRISTOFORO COLOMBO, 5, 21041 ALBIZZATE (VA)',
        email: 'info@vibramfivefingers.it',
        tel: '0039 0331 999 777',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '鞋',
        typeName: 'a2',
        product_en: 'Footwear',
        top: '2245px',
        left: '913px',
    },
    {
        id: 38,
        name: 'VIEFFE NOSELAB SRL',
        web: 'www.coquilleteparfum.com/',
        area_zh: '伦巴第',
        area_en: 'Lombardia',
        address: 'Via Corrado Alvaro n. 10,Milano - Italia',
        email: 'info@coquilletteparfum.com',
        tel: '0039 0287186616',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '香水',
        typeName: 'a7',
        product_en: 'Perfume',
        top: '2323px',
        left: '918px',
    },
    {
        id: 39,
        name: 'DI BI GROUP',
        web: 'www.dibigroup.com/en/',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'Via Einaudi 2 Z.I. 61032 FANO (PU) Italy',
        email: 'info@dibigroup.com',
        tel: '0039 0721 819 1',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '防盗门、墙板、窗户',
        typeName: 'c10',
        product_en: 'Security doors, panels and windows',
        top: '2983px',
        left: '1865px',
    },
    {
        id: 40,
        name: "BRUNEL SRL PREZIOSI D'AUTORE",
        web: 'brunel.it/',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: '62018 Potenza Picena (MC), Italy',
        email: 'info@brunel.it',
        tel: '0039 0733 884011',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '饰品、摆件',
        typeName: 'c11',
        product_en: 'Art Craftsmanship',
        top: '3177px',
        left: '1992px',
    },
    {
        id: 41,
        name: 'IGUZZINI ILLUMINAZIONE SPA',
        web: 'www.iguzzini.com/cn/',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'VIA MARIANO GUZZINI 37, 62019 RECANATI (MC)',
        email: 'luca.tarsetti@iguzzini.cn',
        tel: '0039 071 75881',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '3150px',
        left: '1905px',
    },
    {
        id: 42,
        name: 'BODYFLY SYSTEM SRLS',
        web: 'www.bodyfly.com',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'Via Gattoni 1/2, 61121 Pesaro (PU)',
        email: 'info@bodyfly.com',
        tel: '0039 339 545 1366',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '健身板',
        typeName: 'a8',
        product_en: 'Healthcare',
        top: '3052px',
        left: '1920px',
    },
    {
        id: 43,
        name: 'PIERMARIA NEW FORM SRL',
        web: 'www.piermariadesign.it',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'via Apsa 24/26, 61025 Montelabbate (PU)',
        email: 'info@piermarianewform.it',
        tel: '0039 0721 1391027',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '3005px',
        left: '1850px',
    },
    {
        id: 44,
        name: 'SCAVOLINI',
        web: 'www.scavolini.com',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'Via Risara, 60/70 - 74/78, 61025 Montelabbate (PU)',
        email: 'contatti@scavolini.com',
        tel: '0039 0721 443333',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '橱柜',
        typeName: 'b3',
        product_en: 'Kitchen Furniture',
        top: '3047px',
        left: '1970px',
    },
    {
        id: 45,
        name: 'OFIR GIOIELLI',
        web: 'www.ofirgioielli.com',
        area_zh: '皮埃蒙特',
        area_en: 'Piemonte',
        address: 'Corso Giacomo Matteotti, 59, 15048 Valenza AL, Italy',
        email: 'welcome@ofirgioielli.com',
        tel: '0039 0131 943200',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '2522px',
        left: '763px',
    },
    {
        id: 46,
        name: 'MARCHISIO 1959',
        web: 'www.marchisio1859.com',
        area_zh: '皮埃蒙特',
        area_en: 'Piemonte',
        address: 'Strada Torino n.79 10024,Moncalieri (TO)',
        email: 'alex@marchisio1859.com',
        tel: '0039 0116610417',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '2492px',
        left: '449px',
    },
    {
        id: 47,
        name: 'DESMOTEC SRL',
        web: 'www.desmotec.com',
        area_zh: '皮埃蒙特',
        area_en: 'Piemonte',
        address: 'VIA PIETRO BORA 10, 13900 Biella (BI)',
        email: 'info@desmotec.com',
        tel: '0039 015 582 2096',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '健身器材',
        typeName: 'c12',
        product_en: 'Gym equipment',
        top: '2295px',
        left: '495px',
    },
    {
        id: 48,
        name: 'DOMUS SRL',
        web: 'en.domus-homecollection.com/',
        area_zh: '普利亚',
        area_en: 'Puglia',
        address: 'Viale del Mattino, 3473046 Matino (LE)',
        email: 'info@domus-homecollection.com',
        tel: '0039 371 431 9774',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '床品',
        typeName: 'c4',
        product_en: 'Bedding',
        top: '4472px',
        left: '3460px',
    },
    {
        id: 49,
        name: 'LE DELLI SANTI SRLS',
        web: 'www.ledellisanti.com/en/',
        area_zh: '普利亚',
        area_en: 'Puglia',
        address: 'Via G. Nocera, 5 – 74016 Massafra (TA)',
        email: 'info@ledellisanti.com',
        tel: '0039 3517970232',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '4257px',
        left: '3084px',
    },
    {
        id: 50,
        name: 'NEROSICILIA  GROUP',
        web: 'nerosicilia.group/',
        area_zh: '西西里',
        area_en: 'Sicilia',
        address: 'Strada Provinciale Comiso-Chiaramonte km 6, 97012 Chiaramonte Gulfi (RG)',
        email: 'info@nerosicilia.com',
        tel: '0039 0932963254',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '洁具',
        typeName: 'c5',
        product_en: 'Sanitary Appliances',
        top: '5723px',
        left: '2372px',
    },
    {
        id: 51,
        name: 'OROGRAFIE SRL',
        web: 'www.orografie.com',
        area_zh: '西西里',
        area_en: 'Sicilia',
        address: 'VIA MILANO, 40, 95127 Catania (CT)',
        email: 'info@orografie.com',
        tel: '0039 380 7788060',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '5534px',
        left: '2508px',
    },
    {
        id: 52,
        name: 'FORMITALIA GROUP SPA',
        web: 'www.formitalia.it/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'via Corticella 5/7/9, 51039 Valenzatico (PT)',
        email: 'info@formitalia.it',
        tel: '0039 0573 790066',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '3006px',
        left: '1314px',
    },
    {
        id: 53,
        name: 'SAVIO FIRMINO',
        web: 'www.saviofirmino.com/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Delle Fonti, 10 50018 Scandicci - (FI)',
        email: 'info@saviofirmino.com',
        tel: '0039 055 720466',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2940px',
        left: '1242px',
    },
    {
        id: 54,
        name: 'BUSATTI SRL',
        web: 'shop.busatti.com/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Mazzini, 14 52031 Anghiari (AR)',
        email: 'shop@busatti.com',
        tel: '0039 0575 788013',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '家居用品',
        typeName: 'c13',
        product_en: 'Furnishing',
        top: '3051px',
        left: '1640px',
    },
    {
        id: 55,
        name: 'BEDIN MARMI',
        web: 'www.bedinimarmi.com/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Viale Galileo Galilei 48P 54033  Marina di Carrara (MS)',
        email: 'info@bedinimarmi.com',
        tel: '0039 0585 857895',
        type: 4,
        type_en: 'Finish material',
        product_zh: '石材及相关产品',
        typeName: 'd8',
        product_en: 'marble, stones and granites',
        top: '2380px',
        left: '1503px',
    },
    {
        id: 56,
        name: 'PERONI FIRENZE',
        web: 'peronifirenze.com/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Guglielmo Marconi 82r int, 50131 Firenze (FI)',
        email: 'info@peronifirenze.it',
        tel: '0039 055572520',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '皮具',
        typeName: 'a5',
        product_en: 'Leather products',
        top: '3033px',
        left: '1365px',
    },
    {
        id: 57,
        name: 'AGRESTI',
        web: 'www.agresti.com/en',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Don Lorenzo Perosi n°1 - 50018 Scandicci (FI)',
        email: 'agresti@agresti.com',
        tel: '0039 055 750928',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '保险柜',
        typeName: 'b5',
        product_en: 'Safe box',
        top: '3039px',
        left: '1316px',
    },
    {
        id: 58,
        name: 'TARGETTI',
        web: 'www.targetti.com',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Pratese, 164 50145 Firenze (FI)',
        email: 'targetti@targetti.com',
        tel: '0039  055 37911',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '2993px',
        left: '1410px',
    },
    {
        id: 59,
        name: 'FRATELLI BORGIOLI SRL',
        web: 'www.fratelliborgioli.com',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Maremmana, 17150059 Vinci (FI)',
        email: 'hello@fratelliborgioli.com',
        tel: '0039 571585881',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '鞋',
        typeName: 'a2',
        product_en: 'Footwear',
        top: '2950px',
        left: '1218px',
    },
    {
        id: 60,
        name: 'ALBERO BAMBINO',
        web: 'www.alberobambino.it/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Provinciale, 83 50038 Scarperia e San Piero (FI)',
        email: 'info@alberobambino.it',
        tel: '0039 0552694819',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2902px',
        left: '1349px',
    },
    {
        id: 61,
        name: 'Maska Srl',
        web: 'www.maska.it/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via A. Gramsci n 21, 50022 Greve in Chianti (Fi)',
        email: 'info@maska.it',
        tel: '0039  3207551400',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '服饰',
        typeName: 'a3',
        product_en: 'Clothing',
        top: '3070px',
        left: '1425px',
    },
    {
        id: 62,
        name: 'MARIONI SRL',
        web: 'www.marioni.it',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'VIA G.GIUSTI 199, 50041 Calenzano (FI)',
        email: 'marioni@marioni.it',
        tel: '0039 055 887 9346',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具、饰品',
        typeName: 'b6',
        product_en: 'Furniture,Ornaments',
        top: '2968px',
        left: '1402px',
    },
    {
        id: 63,
        name: 'CORIUM ITALIA SRL',
        web: 'www.coriumitalia.com/home.php',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'VIA Montalbano,169,51039 Quarrata,PistoiaI,Italia',
        email: 'coriumitalia@coriumitalia.com',
        tel: '0039 0573.1915711',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '沙发',
        typeName: 'b7',
        product_en: 'Sofa',
        top: '2868px',
        left: '1194px',
    },
    {
        id: 64,
        name: 'MARIGROUP & CO SRL',
        web: 'kontessa.it/',
        area_zh: '托斯卡纳',
        area_en: 'Toscana',
        address: 'Via Regno Unito, n. 23 zona ind. La Tura fraz. Cascine di Buti56032 Buti (Pisa) Italy',
        email: 'press@kontessa.it',
        tel: '0039 0587723484',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '服饰',
        typeName: 'a3',
        product_en: 'Clothing',
        top: '2901px',
        left: '1098px',
    },
    {
        id: 65,
        name: 'OROGAMI DI TIZIANA ORCIUOLO',
        web: 'www.orogami.com',
        area_zh: '翁布里亚',
        area_en: 'Umbria',
        address: 'Via del Duomo 14/16, 05018, Orvieto (TR)',
        email: 'orogami@orogami.com',
        tel: '0039 0763 344206',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '3456px',
        left: '1616px',
    },
    {
        id: 66,
        name: 'PRODITAL ITALIA',
        web: 'www.prodital.it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via L. Da Vinci 34 - Arzignano (VI)',
        email: 'info@prodital.it',
        tel: '0039 0444 482354',
        type: 4,
        type_en: 'Finish material',
        product_zh: '皮料',
        typeName: 'd9',
        product_en: 'Leather',
        top: '2073px',
        left: '1608px',
    },
    {
        id: 67,
        name: 'SARTOR GIULIANO',
        web: 'www.sartor1956.com/',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Fenadora 49 - 32030 Fonzaso (BL) ITALIA',
        email: 'info@sartor1956.com',
        tel: '0039 0439 1998412',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '1980px',
        left: '1575px',
    },
    {
        id: 68,
        name: 'POINT SRL- brand Pointhouse Creative',
        web: 'www.pointhouse.it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'via Veneto, 10 - 31040 Gorgo al Monticano (TV)',
        email: 'i.greguol@pointhouse.it',
        tel: '0039 0422 800019',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2241px',
        left: '1669px',
    },
    {
        id: 69,
        name: 'PRANE DESIGN',
        web: 'www.pranedesign.com/',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Col Moschin, 3, 35018 San Martino di Lupari (PD)',
        email: 'info@pranedesign.com',
        tel: '0039 049 9470706',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '沙发',
        typeName: 'b7',
        product_en: 'Sofa',
        top: '2305px',
        left: '1548px',
    },
    {
        id: 70,
        name: 'GSC LIGHTING',
        web: 'www.gsclight.com',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Viale del Lavoro 30 35010-Vigonza(PD)',
        email: 'graziano.costantino@gsclight.com',
        tel: '0039 0492328947',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '2327px',
        left: '1561px',
    },
    {
        id: 71,
        name: 'ANNIE CLAIRE Quality Furniture srl',
        web: 'www.annieclaire.it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'via Roma 113, 36060 Schiavon (VI)',
        email: 'info@annieclaire.eu',
        tel: '0039 0444 466939',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2271px',
        left: '1436px',
    },
    {
        id: 72,
        name: 'DANI SPA',
        web: 'www.gruppodani.com/',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Della Concia, 186, 36071 – Arzignano (VI)',
        email: 'info@gruppodani.it',
        tel: '0039 0444 454111',
        type: 4,
        type_en: 'Finish material',
        product_zh: '皮料',
        typeName: 'd9',
        product_en: 'Leather',
        top: '2317px',
        left: '1459px',
    },
    {
        id: 73,
        name: 'ALBERTA PACIFIC FURNITURE',
        web: 'www.alberta.it/',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Toniolo, 41, 31028 Vazzola (TV)',
        email: 'contact@albertasalotti.it',
        tel: '0039 0438440440',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2138px',
        left: '1584px',
    },
    {
        id: 74,
        name: 'BAROVIER & TOSO - Vetrerie artistiche riunite Srl',
        web: 'www.barovier.com/it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Fondamenta Vetrai, 28, 30141 Murano (VE)',
        email: 'info@barovier.com',
        tel: '0039 041 739 049',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '2292px',
        left: '1600px',
    },
    {
        id: 75,
        name: 'FONTANA PIETRO S.P.A.(AltreForme)',
        web: 'www.fontana-group.com',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'VIALE ALCIDE DE GASPERI, 16, 23801 Brendola (VI)',
        email: 'info@fontana-group.com',
        tel: '0039 0341 6381',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2270px',
        left: '907px',
    },
    {
        id: 76,
        name: 'GRASSI PIETRE SRL',
        web: 'www.grassipietre.it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Madonetta, 2, 36024 Nanto (VI) - Italy',
        email: 'info@grassipietre.it',
        tel: '0039 0444 639092',
        type: 4,
        type_en: 'Finish material',
        product_zh: '石材制品',
        typeName: 'd10',
        product_en: 'Stone products',
        top: '2350px',
        left: '1334px',
    },
    {
        id: 77,
        name: 'MAGIS SPA',
        web: 'www.magisdesign.com',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'via Triestina -accesso E - zona Pontetezze, 30020 Torre di Mosto (VE)',
        email: 'info@magisdesign.com',
        tel: '0039 0421319600',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2208px',
        left: '1790px',
    },
    {
        id: 78,
        name: 'Memmo-Venezia S.r.l.',
        web: 'www.memmo-venezia.com',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Campo San Pantalon 3700, 30123 Venezia (VE)',
        email: 'info@memmo-venezia.com',
        tel: '0039 351 5660141',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '水晶玻璃制品',
        typeName: 'c14',
        product_en: 'Glass products',
        top: '2334px',
        left: '1796px',
    },
    {
        id: 79,
        name: 'TAROCCO VACCARI GROUP SRL',
        web: 'www.taroccovaccarigroup.it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Cappello 14 - 37051 Bovolone (VR)',
        email: 'info@taroccovaccarigroup.it',
        tel: '0039 045 7101225',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2664px',
        left: '1370px',
    },
    {
        id: 80,
        name: '	THE HIVE S.R.L.',
        web: 'www.thehiveitalia.com',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: "Viale Del'Industria 19,35129 (PD)",
        email: 'thehiveitalia@gmail.com',
        tel: '0039 327 7697101',
        type: 1,
        type_en: 'Fashion product',
        product_zh: '服饰',
        typeName: 'a3',
        product_en: 'Clothing',
        top: '2431px',
        left: '1496px',
    },
    {
        id: 81,
        name: 'IDL EXPORT SRL',
        web: 'www.idl1987.it',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via S. Marco, 14, 31050 Fanzolo TV, Italy',
        email: 'info@idlexport.it',
        tel: '0039 0423487141',
        type: 3,
        type_en: 'Furnishing',
        product_zh: '灯具',
        typeName: 'c2',
        product_en: 'lIghting',
        top: '2960px',
        left: '1424px',
    },
    {
        id: 82,
        name: 'Modenese Gastone Interiors s.r.l.',
        web: 'modeneseinteriors.com/',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Roma st, 92/106, 35040, Casale di Scodosia,Padova, Italy',
        email: 'info@modeneseinteriors.com',
        tel: '0039 0429 879146',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2399px',
        left: '1389px',
    },
    {
        id: 83,
        name: 'CAFEDESART',
        web: 'cafedesart.com/en/',
        area_zh: '威尼托',
        area_en: 'Veneto',
        address: 'Via Muri 44, 37050 Bonavicina San Pietro di Morubio, VR (Italy)',
        email: 'sales@cafedesart.com',
        tel: '0039 045 712 5095',
        type: 2,
        type_en: 'Furniture  product',
        product_zh: '家具',
        typeName: 'b2',
        product_en: 'Furniture',
        top: '2433px',
        left: '1427px',
    },
    {
        id: 84,
        name: 'LEONORI',
        web: 'www.leonorigioielli.com',
        area_zh: '马尔凯',
        area_en: 'Marche',
        address: 'C.so Vittorio Emanuele,2 63100 Ascoli Piceno(AP) Italy',
        email: 'info@leonorigioielli.com',
        tel: '0039 342 5772788',
        type: 1,
        type_en: 'Fashion  product',
        product_zh: '首饰',
        typeName: 'a1',
        product_en: 'Jewelry',
        top: '3320px',
        left: '2076px',
    },
];