<template>
    <div class="base-dialog-wrapper" @click="onClose" :class="$style['tips-page-wrapper']">
        <img style="width: 50%; display: block; object-fit: contain" src="@/assets/imgs/finger.png" alt="" />
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
const props = defineProps({
    close: {
        type: Function,
    },
});

function onClose() {
    props.close();
    // document.addEventListener(
    //     'touchmove',
    //     function (e) {
    //         e.preventDefault();
    //     },
    //     {
    //         passive: false,
    //     },
    // );
}
</script>

<style lang="scss" module>
@import '@/styles/themes.scss';

.tips-page-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
}
</style>
